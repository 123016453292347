<template>
  <div>
    <div class="tree-list">
      <el-tree :data="data"
               :props="defaultProps"
               :check-strictly="true"
               show-checkbox
               :expand-on-click-node="false"
               ref="tree"
               node-key="realId"
               @check="handleNodeChange">
      </el-tree>
    </div>
    <div class="tree-detail" v-if="showFlag">
      <el-button type="primary" @click="addObject">增加根节点</el-button>
      <el-col :offset="5" :span="12">
        <el-form ref="BDInfo" :model="BDInfo" label-width="80px" :rules="rules">
          <el-form-item label="父项id">
            <el-input :value="BDInfo.parentNum === '0'?'根元素':BDInfo.parentNum" :width="100" disabled></el-input>
          </el-form-item>
          <el-form-item label="本项id" prop="dataNum">
            <el-input v-model="BDInfo.dataNum"></el-input>
          </el-form-item>
          <el-form-item label="字典内容" prop="dataInfo">
            <el-input v-model="BDInfo.dataInfo" :width="100"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model="BDInfo.sort" :width="100"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input v-model="BDInfo.remarks" :width="100"></el-input>
          </el-form-item>
        </el-form>
        <el-row>
          <el-button type="primary" @click="updateBD">保存</el-button>
          <el-button type="primary" @click="addBD">新增子节点</el-button>
          <el-button type="danger" @click="removeBD">删除节点及子节点</el-button>
        </el-row>
      </el-col>
    </div>
    <div>
      <el-dialog title="新增节点" :visible.sync="dialogFormVisible" width="50%">
        <div style="height: 100%" class="el-dialog-div">
          <el-form :model="BD" :rules="rules" ref="BD">
            <el-col :offset="5" :span="12">
              <el-form-item label="父项id" :label-width="formLabelWidth">
                <el-input :value="BD.parentNum === '0'?'根节点':BD.parentNum" autocomplete="off" disabled></el-input>
              </el-form-item>
              <el-form-item label="本项id" :label-width="formLabelWidth" prop="dataNumSub">
                <el-col :span="12">
                  <el-input :value="BD.parentNum === '0'?'根节点':BD.parentNum" autocomplete="off" disabled></el-input>
                </el-col>
                <el-col :span="12">
                  <el-input v-model="BD.dataNumSub" autocomplete="off"></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="字典内容" :label-width="formLabelWidth" prop="dataInfo">
                <el-input v-model="BD.dataInfo" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="排序" :label-width="formLabelWidth" prop="sort">
                <el-input v-model="BD.sort" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="备注" :label-width="formLabelWidth" prop="remarks">
                <el-input v-model="BD.remarks" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-form>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveBD">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>


<script>
import {getAllDbInfo, saveDB, updateDB, removeDB} from '@/api/backStage/dictionary'

export default {
  data() {
    return {
      data: [],
      defaultProps: {
        children: 'children',
        label: 'dataInfo'
      },
      checkedId: {},
      showFlag: false,
      buttonFlag: false,
      BDInfo: {
        realId: '',
        parentNum: '',
        dataNum: '',
        dataInfo: '',
        sort: '',
        remarks: '',
        delFlag: '',
        createTime: '',
        createBy: '',
        updateTime: '',
        updateBy: '',
        children: {}
      },
      BD: {
        parentNum: '',
        dataNumSub: '',
        dataInfo: '',
        sort: '',
        remarks: ''
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      rules: {
        dataNumSub: [
          {required: true, message: '请输入本项id', trigger: 'blur'},
        ],
        dataInfo: [
          {required: true, message: '请输入本项数据', trigger: 'blur'},
        ],
        dataNum: [
          {required: true, message: '请输入本项id', trigger: 'blur'},
        ],
        sort: [
          {required: true, message: '请输入排序', trigger: 'blur'},
        ],
        remarks: [
          {required: true, message: '请输入备注', trigger: 'blur'},
        ]
      }
    };
  },
  components: {},
  methods: {
    handleNodeChange(data, checked) {
      this.showFlag = true;
      this.buttonFlag = false;
      this.BDInfo = data;
      if (checked) {
        this.$refs.tree.setCheckedKeys([data.realId], true)
      }
    },
    addBD() {
      this.dialogFormVisible = true;
      this.BD.parentNum = this.BDInfo.dataNum;
      this.BD.dataNumSub = '_' + ((this.BDInfo.children !== undefined && this.BDInfo.children.length) >= 9 ? (this.BDInfo.children.length + 1) : '0' + (this.BDInfo.children !== undefined ? (this.BDInfo.children.length + 1) : '1'));
      this.BD.sort = (this.BDInfo.children !== undefined ? this.BDInfo.children.length + 1 : '1');
      this.showFlag = false;
    },
    addObject() {
      this.dialogFormVisible = true;
      this.BD.parentNum = '0';
    },
    saveBD() {
      this.$refs['BD'].validate((valid) => {
        if (valid) {
          const params = {
            parentNum: this.BD.parentNum,
            dataNum: this.BD.parentNum === '0' ? this.BD.dataNumSub : this.BD.parentNum + this.BD.dataNumSub,
            dataInfo: this.BD.dataInfo,
            sort: this.BD.sort,
            remarks: this.BD.remarks,
          };
          saveDB(params).then(res => {
            if (res.code == 0) {
              this.dialogFormVisible = false
              this.getAllDbInfo();
            }
          }).catch(error => console.error(error));
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    updateBD() {
      this.$refs['BDInfo'].validate((valid) => {
        if (valid) {
          const params = {
            id: this.BDInfo.realId,
            parentNum: this.BDInfo.parentNum,
            dataNum: this.BDInfo.dataNum,
            dataInfo: this.BDInfo.dataInfo,
            sort: this.BDInfo.sort,
            remarks: this.BDInfo.remarks,
          };
          updateDB(params).then(res => {
            if (res.code == 0) {
              this.dialogFormVisible = false
              this.getAllDbInfo();
            }
          }).catch(error => console.error(error));
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    removeBD() {
      this.$confirm('此操作将删除该节点及其子节点, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          dataNum: this.BDInfo.dataNum,
        };
        removeDB(params).then(res => {
          if (res.code == 0) {
            this.dialogFormVisible = false;
            this.showFlag = false;
            // this.$message({message: res.msg, type: 'success'});
            this.getAllDbInfo();
          } else {
            this.$message.error(res.msg)
          }
        }).catch(error => console.error(error));
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    getAllDbInfo() {
      const params = {};
      getAllDbInfo(params).then(res => {
        console.log(res)
        if (res.code == 0) {
          this.BD = {};
          this.data = res.data;
          this.$refs.tree.setCheckedKeys([]);
        } else {
          this.$message.error(res.msg)
        }
      }).catch(error => console.error(error));
    }
  },
  created() {
    this.getAllDbInfo();
  }
}
</script>

<style scoped>
.tree-list {
  padding: 30px;
  height: auto;
  width: 40%;
  float: left;
  font-size: 12px;
}

.tree-detail {
  background-color: white;
  margin: 30px;
  padding: 50px 0;
  height: auto;
  width: 50%;
  float: left;
  text-align: center;
}

.el-dialog-div {
  height: 60vh;
  overflow: auto;
}
</style>
