import Request from '@/utils/request';

// 获取工具列表
export const getToolList = (params) => Request.get('/chick/tools/list', { data: { ...params }, loading: false });
// 生成UUID
export const generateUUIDD = (params) => Request.get('/chick/tools/generateUUID', { data: { ...params }, loading: false });
// 随机密码生成
export const generateRandomCipher = (params) => Request.get('/chick/tools/generateRandomCipher', { data: { ...params }, loading: false });
// base64编码解码
export const base64EncodeOrDecode = (params) => Request.get('/chick/tools/base64EncodeOrDecode', { data: { ...params }, loading: false });
// 生成二维码
export const createQRCode = (params) => Request.get('/chick/tools/createQRCode', { data: { ...params }, loading: false });
// 解析二维码
export const distinguishQRCode = (params) => Request.post('/chick/tools/distinguishQRCode', { data: { ...params }, loading: false });


// 运费保存模板
export const freightSave = (params) => Request.post('/chick/tools/freightSave', { data: { ...params }, loading: false });

// 运费更新模板
export const freightUpdate = (params) => Request.post('/chick/tools/freightUpdate', { data: { ...params }, loading: false });

// 模板列表查询
export const freightList = (params) => Request.get('/chick/tools/freightList', { data: { ...params }, loading: false });
// 模板详情
export const freightDetail = (params) => Request.get('/chick/tools/freightDetail', { data: { ...params }, loading: false });

// 删除模板
export const freightDel = (params) => Request.delete('/chick/tools/freightDel', { data: { ...params }, loading: false });

// 计算运费
export const freight = (params) => Request.post('/chick/tools/freight', { data: { ...params }, loading: false });

// 新增自定义城市
export const addAreaGroup = (params) => Request.get('/chick/tools/addAreaGroup', { data: { ...params }, loading: false });

// 查询自定义城市
export const getAreaGroup = (params) => Request.get('/chick/tools/getAreaGroup', { data: { ...params }, loading: false });

// 删除自定义城市
export const deleteAreaGroup = (params) => Request.delete('/chick/tools/deleteAreaGroup', { data: { ...params }, loading: false });

// 查询市级树
export const getCity = (params) => Request.get('/chick/tools/getCity', { data: { ...params }, loading: false });
