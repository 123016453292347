<template>
  <div class="UUIDContent">
    <div class="tip" style="text-align: left">
      UUID在线生成
    </div>
    <div style="padding: 0 0 0 25%">
      <span style="float: left;font-size: 27px;margin: 0 20px 0 0">生成个数:</span>
      <el-slider
          style="width: 50%;float: left"
          v-model="count"
          show-input>
      </el-slider>
    </div>
    <br>
    <br>
    <el-checkbox v-model="horizontalBar">是否去除 - </el-checkbox>
    <br><br><br>
    <el-button @click="generate" type="primary">生成</el-button>

    <div v-for="(item, index) in UUIDList" :key="index" style="margin: 10px 0 0 0;font-size: 25px">
      <span>{{ item }}</span>
    </div>
  </div>
</template>

<script>
import {generateUUIDD} from '@/api/frontStage/tools'
export default {
  name: "UUID",
  data() {
    return {
      count: 5,
      horizontalBar: false,
      UUIDList: []
    }
  },
  methods: {
    generate() {
      generateUUIDD({count: this.count, horizontalBar: this.horizontalBar}).then(res => {
        this.UUIDList = res.data;
      })
    }
  }
}
</script>

<style scoped>
.UUIDContent {
  text-align: center;
  min-height: 500px;
  height: auto;
  width: 100%;
  background-color: white;
}

.tip {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 0 0 20px 0;
  font-size: 40px;
}

</style>
