<template>
  <div>
    <div class="container" style="margin: 20px">
      <div class="handle-box">
        <el-input v-model="query.keyword" placeholder="考试详情名" class="handle-input mr10"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        <!--考试名筛选-->
        <el-select v-model="query.examId" class="mr10" style="vertical-align:top;margin-left: 10px"
                   @change="changeExam" placeholder="考试名筛选">
          <el-option v-for="(item, index) in examList" :key="index" :value="item.id" :label="item.name"></el-option>
        </el-select>
        <!--考试详情筛选-->
        <el-select v-model="query.detailId" class="mr10" style="vertical-align:top;margin-left: 10px"
                   @change="changeExamDetail" placeholder="考试详情筛选">
          <el-option v-for="(item, index) in examDetailList" :key="index" :value="item.id"
                     :label="item.detailName"></el-option>
        </el-select>

        <!--考试科目筛选-->
        <el-select v-model="query.subjectId" class="mr10" style="vertical-align:top;margin-left: 10px"
                   @change="changeExamSubject" placeholder="考试科目筛选">
          <el-option v-for="(item, index) in examSubjectList" :key="index" :value="item.id"
                     :label="item.name"></el-option>
        </el-select>


        <el-select v-model="query.delFlag" class="mr10" style="vertical-align:top;margin-left: 10px"
                   @change="getData">
          <el-option :value="0" label="正常考试详情"></el-option>
          <el-option :value='1' label="已删除考试详情"></el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-plus" @click="add">添加考试详情</el-button>
      </div>
      <el-table
          :data="tableData"
          border
          stripe
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="name" label="题目"  :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="parse" label="解析" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="type" label="类型">
          <template slot-scope="scope">
            {{ scope.row.type | dickFilter('QUESTION') }}
          </template>
        </el-table-column>
        <el-table-column prop="takeUp" label="占用题目个数"></el-table-column>

        <el-table-column label="所属考试">
          <template slot-scope="scope">
            {{ scope.row.id | getAffiliatedExam()}}
          </template>
        </el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">
            {{ scope.row.type | dickFilter('EXAM') }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="280" align="center">
          <template slot-scope="scope">
            <el-button
                type="text"
                icon="el-icon-edit"
                class="success"
                @click="handleEdit(scope.$index, scope.row)"
                v-if="scope.row.delFlag === '0'"
            >编辑
            </el-button>
            <el-button
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="handleDelete(scope.$index, scope.row)"
            >{{ scope.row.delFlag === '0' ? '删除' : '恢复' }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="prev, pager, next, jumper"
            :current-page="query.current"
            :page-size="query.size"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 添加或编辑 -->
    <el-dialog title="添加或编辑试题" :visible.sync="editVisible" width="580px" top="1vh">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="考试名" class="required" required style="width: 500px">
          <el-select v-model="addData.examId" class="mr10" style="vertical-align:top;margin-left: 10px" @change="changeExamAdd" placeholder="考试名">
            <el-option v-for="(item, index) in examList" :key="index" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试详情" class="required" required style="width: 500px">
          <el-select v-model="addData.detailId" class="mr10" style="vertical-align:top;margin-left: 10px" @change="changeExamDetailAdd" placeholder="考试详情">
            <el-option v-for="(item, index) in addExamDetailList" :key="index" :value="item.id" :label="item.detailName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="题目" class="required" required style="width: 500px">
          <el-select v-model="addData.subjectId" class="mr10" style="vertical-align:top;margin-left: 10px" @change="changeExamSubjectAdd" placeholder="考试题目">
            <el-option v-for="(item, index) in addExamSubjectList" :key="index" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="知识点分类" class="required" required style="width: 500px">
          <el-select v-model="addData.questionTypeId" class="mr10" style="vertical-align:top;margin-left: 10px" placeholder="知识点分类">
            <el-option v-for="(item, index) in addExamQuestionTypeList" :key="index" :value="item.id"
                       :label="item.name"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="题目" class="required" required style="width: 500px">
          <el-input v-model="addData.name"></el-input>
        </el-form-item>
        <el-form-item label="解析" class="required" required style="width: 500px">
          <el-input v-model="addData.parse"></el-input>
        </el-form-item>
        <el-form-item label="类型" class="required" required style="width: 500px">
          <el-input v-model="addData.type"></el-input>
        </el-form-item>
        <el-form-item label="占用题目个数" class="required" required style="width: 500px">
          <el-input v-model="addData.takeUp"></el-input>
        </el-form-item>
        <el-form-item label="是否开放" style="width: 500px;margin: 20px 0 0 0;" required>
          <el-switch
              v-model="addData.open"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="0"
              :inactive-value="1"
              style="float: left;margin: 10px 0 0 10px">
          </el-switch>
        </el-form-item>
        <el-form-item label="考试类型" style="width: 500px;margin: 20px 0 0 0;" required>
          <el-cascader
              v-model="addData.type"
              :options="examTypeDb"
              :collapse-tags="true"
              :props="{ multiple: false, checkStrictly: true , label:'dataInfo', value:'dataNum', expandTrigger: 'hover'}"
              clearable
              style="width: 370px"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveAdd">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>

import {
  getChildrenDataForManager,
  getExamQuestionList,
  getExamListInExamDetail,
  getExamSubjectList,
  saveExamQuestion,
  updateExamQuestion,
  removeOrRenewExamQuestion,
  removeOrRenewExamQuestionType,
  saveExamQuestionType, getExamDetailByExamIdInQuestion, getExamSubjectByExamIdInQuestion
} from "@/api/backStage/exam";

export default {
  name: 'filmTable',
  data() {
    return {
      //查询参数
      query: {
        current: 1,
        size: 10,
        keyword: '',
        delFlag: 0,
        examId: '',
        detailId: '',
        subjectId: '',
        types: '',
        typesStr: ''
      },
      // 考试详情数组
      tableData: [],
      addData: {
        id: '',
        examId: '',
        DetailId: '',
        subjectId: '',
        questionTypeId: '',
        name: '',
        parse: '',
        type: '',
        takeUp: '',
        answers: []
      },
      examList: [],
      examDetailList: [],
      examSubjectList: [],
      examQuestionTypeList: [],
      addExamDetailList: [],
      addExamSubjectList: [],
      addExamQuestionTypeList: [],

      examTypeDb: [],
      examSubject: [],
      editVisible: false,
      pageTotal: 1,
      loading: false,
      form: {},
    };
  },
  dicts: ['ALL-EXAM', 'QUESTION'],
  filters : {
    getAffiliatedExam(){
      return 'aaa'
    }
  },
  created() {
    this.getData();
    getChildrenDataForManager({key: 'EXAM', childrenOfChildren: true}).then(res => {
      console.log(res.data[0].children)
      this.examTypeDb = res.data[0].children;
    })
    // 查询考试
    getExamListInExamDetail().then(res => {
      this.examList = res.data;
    })
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    getData() {
      const data = {
        keyword: this.query.keyword,
        current: this.query.current,
        size: this.query.size,
        delFlag: this.query.delFlag,
        examId: this.query.examId,
        detailId: this.query.detailId,
        subjectId: this.query.subjectId
      }
      // 查询考试详情
      getExamQuestionList(data).then(res => {
        this.tableData = res.data.records;
        this.pageTotal = res.data.total;
      })
    },
    // 查询
    changeExam() {
      this.query.detailId = '';
      getExamDetailByExamIdInQuestion({examId: this.query.examId}).then(res => {
        console.log(res);
        this.examDetailList = res.data;
      })
      this.getData();
    },
    changeExamDetail() {
      this.query.subjectId = '';
      getExamSubjectByExamIdInQuestion({examId: this.query.examId, detailId: this.query.detailId}).then(res => {
        this.examSubjectList = res.data;
      })
      this.getData();
    },
    changeExamSubject() {
      this.getData();
    },
    // 添加/编辑
    changeExamAdd() {
      getExamDetailByExamIdInQuestion({examId: this.addData.examId}).then(res => {
        this.addExamDetailList = res.data;
      })
    },
    changeExamDetailAdd() {
      getExamSubjectByExamIdInQuestion({examId: this.addData.examId, detailId: this.addData.detailId}).then(res => {
        this.addExamSubjectList = res.data;
      })
    },
    changeExamSubjectAdd() {
    },
    examTypeChange(examTypes) {
      this.query.typesStr = '';
      this.query.typesStr = this.getTypeStr(examTypes, this.query.typesStr);
      this.getData();
    },
    getTypeStr(examTypes, typeStrParam) {
      for (let a in examTypes) {
        let data = ((examTypes[a].constructor === Array) ? examTypes[a][examTypes[a].length - 1] : examTypes[a]);
        if (typeStrParam === '') {
          if (typeStrParam.split(',').indexOf(data) === -1) {
            typeStrParam = typeStrParam + data;
          }
        } else {
          if (typeStrParam.split(',').indexOf(data) === -1) {
            typeStrParam = typeStrParam + ',' + data;
          }
        }
      }
      return typeStrParam;
    },
    getTypeStr2(examTypes, typeStrParam) {
      let data = ((examTypes[0].constructor === Array) ? examTypes[0][examTypes[0].length - 1] : examTypes[0]);
      if (typeStrParam === '') {
        if (typeStrParam.split(',').indexOf(data) === -1) {
          typeStrParam = typeStrParam + data;
        }
      } else {
        if (typeStrParam.split(',').indexOf(data) === -1) {
          typeStrParam = typeStrParam + ',' + data;
        }
      }
      return typeStrParam;
    },
    // 保存编辑
    saveAdd() {
      const data = {
        id: this.addData.id,
        examId: this.addData.examId,
        examDetailId: this.addData.examDetailId,
        detailName: this.addData.detailName,
        open: this.addData.open,
        type: this.addData.type.constructor === String ? this.addData.type : this.getTypeStr2(this.addData.type, ''),
        sort: this.addData.sort,
        code: this.addData.code,
        oneSelect: this.addData.oneSelect,
        multipleSelect: this.addData.multipleSelect,
        judge: this.addData.judge,
        fillBlank: this.addData.fillBlank,
        shortAnswer: this.addData.shortAnswer
      }
      //如果id为空走保存，如果id不为空，走编辑
      if (this.addData.id === '') {
        saveExamQuestion(data).then(res => {
          console.log(res);
          this.getData();
          this.editVisible = false;
        });
      } else {
        updateExamQuestion(data).then(() => {
          this.getData();
          this.editVisible = false;
        })
      }
    },
    // 编辑操作
    handleEdit(index, row) {
      this.addData.id = row.id;
      this.addData.examId = row.examId;
      this.addData.examDetailId = row.examDetailId;
      this.addData.detailName = row.detailName;
      this.addData.open = row.open;
      this.addData.type = row.type;
      this.addData.typeStr = row.type;
      this.addData.sort = row.sort;
      this.addData.code = row.code;
      this.addData.oneSelect = row.oneSelect;
      this.addData.multipleSelect = row.multipleSelect;
      this.addData.judge = row.judge;
      this.addData.fillBlank = row.fillBlank;
      this.addData.shortAnswer = row.shortAnswer;
      this.editVisible = true;
    },
    // 新增按钮
    add() {
      this.addData.id = '';
      this.addData.examId = '';
      this.addData.examDetailId = '';
      this.addData.detailName = '';
      this.addData.open = 1;
      this.addData.type = '';
      this.addData.typeStr = '';
      this.addData.sort = '';
      this.addData.code = '';
      this.addData.oneSelect = '';
      this.addData.multipleSelect = '';
      this.addData.judge = '';
      this.addData.fillBlank = '';
      this.addData.shortAnswer = '';
      this.editVisible = true
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, 'pageIndex', 1);
      this.getData();
    },
    getExamSubjectTable(index, row) {
      this.examTypeTableVisible = true;
      //保存当前打开的考试
      if (row != undefined) {
        this.addExamSubject.detailId = row.id
        this.addExamSubject.examId = row.examId
      }
      const data = {
        detailId: this.addExamSubject.detailId,
        examId: this.addExamSubject.examId,
        current: this.queryExamSubjectTable.current,
        size: this.queryExamSubjectTable.size,
        delFlag: this.queryExamSubjectTable.delFlag,
        keyword: this.queryExamSubjectTable.keyword
      }
      getExamSubjectList(data).then(res => {
        this.examSubject = res.data.records;
        this.pageTotal2 = res.data.total;
      })
    },
    handleAdd() {
      this.addExamSubject.id = '';
      this.addExamSubject.name = '';
      this.addExamSubject.sort = '';
      this.examSubjectAddVisible = true
    },
    changeDel(value) {
      this.query.delFlag = value;
      this.getData()
    },
    // 删除操作
    handleDelete(index, row) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      })
          .then(() => {
            const data = {
              id: row.id,
              delFlag: row.delFlag
            }
            removeOrRenewExamQuestion(data).then(() => {
              this.getData()
            });
          })
          .catch(() => {
          });
    },
    contentDelete(index, row) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      })
          .then(() => {
            const data = {
              id: row.id,
            }
            removeOrRenewExamQuestionType(data).then(() => {
              this.getExamSubjectTable();
            });
          })
          .catch(() => {
          });
    },
    saveExamDetail() {
      // 保存考试等级分类
      const data = {
        id: this.addExamSubject.id,
        examId: this.addExamSubject.examId,
        detailId: this.addExamSubject.detailId,
        name: this.addExamSubject.name,
        sort: this.addExamSubject.sort
      }
      saveExamQuestionType(data).then(() => {
        this.examSubjectAddVisible = false;
        this.getExamSubjectTable();
      })
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, 'current', val);
      this.getData();
    },
    handlePageChange2(val) {
      this.$set(this.queryExamSubjectTable, 'current', val);
      this.getExamSubjectTable();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

/*/deep/ .el-cascader__tags{*/
/*  height: 20px;*/
/*}*/
.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
