<template>
  <div>
    <div class="tree-list">
      <el-tree :data="data"
               :props="defaultProps"
               :check-strictly="true"
               show-checkbox
               :expand-on-click-node="false"
               ref="tree"
               node-key="roleId"
               @check="handleNodeChange">
      </el-tree>
    </div>
    <div class="tree-list" v-if="showFlag">
      <el-button type="primary" @click="updateRoleMenu">授权</el-button>
      <el-tree :data="dataRole"
               :props="defaultPropsRole"
               :check-strictly="true"
               :default-checked-keys="checkedKeys"
               show-checkbox
               :expand-on-click-node="false"
               ref="treeRole"
               node-key="menuId">
      </el-tree>
    </div>
  </div>
</template>


<script>
import {getRoleMenu, getRoleMenuByRoleId, updateRoleMenu} from '@/api/backStage/system'

export default {
  data() {
    return {
      data: [],
      defaultProps: {
        children: 'children',
        label: 'roleName'
      },
      dataRole: [],
      defaultPropsRole: {
        children: 'children',
        label: 'name'
      },
      checkedKeys: [],
      checkedRoleId: '',
      showFlag: false,
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth: '120px',
    };
  },
  components: {},
  methods: {
    //角色树
    handleNodeChange(data, checked) {
      this.showFlag = true;
      this.checkedRoleId = data.roleId;
      if (checked) {
        this.$refs.tree.setCheckedKeys([data.roleId], true)
      }
      this.getRoleMenuByRoleId(data.roleId);
    },
    //授权
    updateRoleMenu() {
      this.$confirm('确认授权吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          roleId: this.checkedRoleId,
          menuIds: this.$refs.treeRole.getCheckedKeys()
        };
        updateRoleMenu(params).then(res => {
          if (res.code == 0) {
            //todo
          }
        }).catch(error => console.error(error));
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //获取角色
    getRoleMenu() {
      const params = {};
      getRoleMenu(params).then(res => {
        if (res.code == 0) {
          this.data = res.data;
        } else {
          this.$message.error(res.msg)
        }
      }).catch(error => console.error(error));
    },
    //通过角色id获取权限
    getRoleMenuByRoleId(roleId) {
      const params = {
        roleId: roleId
      };
      getRoleMenuByRoleId(params).then(res => {
        if (res.code == 0) {
          console.log(res.data)
          this.dataRole = res.data;
          //清空选中
          this.checkedKeys = [];
          //设置选中
          this.resolveCheckedKey(res.data);
        } else {
          this.$message.error(res.msg)
        }
      }).catch(error => console.error(error));
    },
    resolveCheckedKey(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].roleId !== null) {
          console.log(data[i].menuId);
          this.checkedKeys.push(data[i].menuId);
        }
        if (data[i].children != null) {
          this.resolveCheckedKey(data[i].children);
        }
      }
    }
  },
  created() {
    this.getRoleMenu();
  }
}
</script>

<style scoped>
.tree-list {
  padding: 30px;
  height: auto;
  width: 40%;
  float: left;
  font-size: 12px;
}

.tree-detail {
  background-color: white;
  margin: 30px;
  padding: 50px 0;
  height: auto;
  width: 50%;
  float: left;
  text-align: center;
}

.el-dialog-div {
  height: 60vh;
  overflow: auto;
}
</style>
