<template>
  <div class="toolsTitle" v-loading="loading" style="height: auto;min-height: 600px;">
    <div style="height: 20px;width: auto;margin: 0 0 20px 0">
      <el-radio v-model="query.radio" label="" @change="changeType">所有</el-radio>
      <el-radio v-model="query.radio" :label="key" v-for="(value, key) in this.dict.TOOL" v-bind:key="key" @change="changeType">
        {{ value }}
      </el-radio>
    </div>
    <el-empty style="width: auto;margin: 0 auto;min-height: 65vh" v-if="toolList.length === 0" description="暂无内容"></el-empty>

    <el-row :gutter="20" style="width: 90%;margin: 0 auto;min-height: 65vh;" v-if="toolList.length > 0">
      <el-col :span="6" v-for="(item,index) in toolList" v-bind:key="index"
              style="text-align:left;margin-bottom: 20px;min-width: 300px">
        <el-card shadow="hover" class="link">
          <div>
            <router-link :to="item.path" style="text-decoration: none;color: black;">
              <el-image :src="item.imgUrl" style="width: 60px;height: 60px;margin: 0 10px 0 0"></el-image>
              <span style="display: inline-block;vertical-align:top">
              {{ item.name }}
            </span>
            </router-link>
            <span style="display: inline-block;font-size: 14px;float: right">
              [{{ item.type | dickFilter('TOOL') }}]
            </span>
          </div>
          <div style="height: 30px;width: 100%;margin: 10px">
            <span> {{ item.describe }}</span>
          </div>
          <el-button @click="addRouter(item.path)" type="primary" style="width: 100%" round>开始使用</el-button>
        </el-card>

      </el-col>
    </el-row>
    <br>
    <div class="pagination">
      <el-pagination
          layout="prev, pager, next, jumper"
          :current-page="query.current"
          :page-size="query.size"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {getToolList} from "@/api/frontStage/tools";

export default {
  name: "ToolIndex",
  data() {
    return {
      query: {
        current: 1,
        size: 16,
        keyword: '',
        delFlag: 0,
        radio:''
      },
      loading: true,
      toolList: [],
      pageTotal: 1
    }
  },
  dicts: ['TOOL'],
  methods: {
    getData() {
      //console.log(localStorage.getItem('TOOL'));//获取数组
      //console.log(this.dict)//获取列表
      this.loading = true;
      const data = {
        keyword: this.query.keyword,
        current: this.query.current,
        size: this.query.size,
        type: this.query.radio,
        delFlag: this.query.delFlag
      }
      getToolList(data).then(res => {
        this.toolList = res.data.records;
        this.pageTotal = res.data.total;
        sessionStorage.setItem('toolCurrent', this.query.current);
        this.loading = false;
      })
    },
    changeType() {
      this.getData();
    },
    handlePageChange(val) {
      this.$set(this.query, 'current', val);
      // target.scrollIntoView();
      this.getData();
    },
    addRouter(path) {
      this.$router.push(path);
    }
  },
  mounted() {
    if (sessionStorage.getItem('toolCurrent') != null) {
      this.query.current = parseInt(sessionStorage.getItem('toolCurrent'));
    }
    this.getData();
  },
  computed: {
    list() {
      return this.$route.matched;
    }
  },
  filters: {
    //处理函数
    addPriceIcon(value) {
      console.log(value)//200
      return '¥' + value
    }
  }
}
</script>

<style scoped>
.toolsTitle {
  text-align: center;
}

.link > a:hover {
  color: dodgerblue;
}
</style>
