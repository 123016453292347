<template>
  <div>
    <div class="tree-list">
      <el-tree :data="data"
               :props="defaultProps"
               :check-strictly="true"
               show-checkbox
               :expand-on-click-node="false"
               ref="tree"
               node-key="menuId"
               @check="handleNodeChange">
      </el-tree>
    </div>
    <div class="tree-detail" v-if="showFlag">
      <el-button type="primary" @click="addObject">增加根节点</el-button>
      <el-col :offset="5" :span="12">
        <el-form ref="backStageMenu" :model="backStageMenu" label-width="80px" :rules="rules">
          <el-form-item label="父项名">
            <el-input :value="getNameById(this.data, this.backStageMenu.parentMenuId) ===null?'根元素':getNameById(this.data, this.backStageMenu.parentMenuId)" :width="100"
                      disabled></el-input>
          </el-form-item>
          <el-form-item label="菜单名称" prop="name">
            <el-input v-model="backStageMenu.name" :width="100"></el-input>
          </el-form-item>
          <el-form-item label="菜单路径" :prop="backStageMenu.path===null? '' : 'path'">
            <el-input v-model="backStageMenu.path" :value="backStageMenu.path===null?'根元素':backStageMenu.path" :width="100"
                      :disabled="backStageMenu.path===null"></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="backStageMenu.sort" :width="100"></el-input>
          </el-form-item>
        </el-form>
        <el-row>
          <el-button type="primary" @click="updateMenu">保存</el-button>
          <el-button type="primary" @click="addMenu">新增子节点</el-button>
          <el-button type="danger" @click="removeMenu">删除节点</el-button>
        </el-row>
      </el-col>
    </div>
    <div>
      <el-dialog title="增加节点" :visible.sync="dialogFormVisible" width="50%">
        <div style="height: 100%" class="el-dialog-div">
          <el-form :model="menu" :rules="rules" ref="menu">
            <el-col :offset="5" :span="12">
              <el-form-item label="父菜单">
                <el-input :value="this.menu.parentMenuName===null?'根节点':this.menu.parentMenuName" autocomplete="off"
                          disabled></el-input>
              </el-form-item>
              <el-form-item label="菜单名称" prop="name">
                <el-input v-model="menu.name" :width="100"></el-input>
              </el-form-item>
              <el-form-item label="菜单路径" :prop="menu.path===null? '' :'path'">
                <el-input v-model="menu.path" :value="menu.path===null?'根元素':menu.path" :width="100"
                          :disabled="menu.path===null"></el-input>
              </el-form-item>
              <el-form-item label="排序">
                <el-input v-model="menu.sort" :width="100"></el-input>
              </el-form-item>
            </el-col>
          </el-form>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveMenu">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>


<script>
import {getBackStageMenu, updateMenu, saveMenu, removeMenu} from '@/api/backStage/system'

export default {
  data() {
    return {
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      checkedId: {},
      showFlag: false,
      buttonFlag: false,
      backStageMenu: {
        menuId: '',
        parentMenuId: '',
        name: '',
        path: '',
        sort: ''
      },
      menu: {
        parentMenuId: '',
        parentMenuName: '',
        menuId: '',
        name: '',
        path: '',
        sort: ''
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      rules: {
        menuId: [
          {required: true, message: '请输入本项id', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请输入菜单名称', trigger: 'blur'},
        ],
        path: [
          {required: true, message: '请输入菜单路径', trigger: 'blur'},
        ]
      }
    };
  },
  components: {},
  methods: {
    getNameById(data, id) {
      for (let i = 0; i < data.length; i++) {
        if (id === data[i].menuId) {
          return data[i].name;
        }
        if (data[i].children != null) {
          this.getNameById(data[i].children);
        }
      }
      return null;
    },
    handleNodeChange(data, checked) {
      this.showFlag = true;
      this.buttonFlag = false;
      this.backStageMenu = data;
      if (checked) {
        this.$refs.tree.setCheckedKeys([data.menuId], true)
      }
    },
    addMenu() {
      this.dialogFormVisible = true;
      this.menu.parentMenuId = this.backStageMenu.menuId;
      this.menu.parentMenuName = this.backStageMenu.name;
      this.menu.path = '';
    },
    addObject() {
      this.dialogFormVisible = true;
      this.menu.parentMenuId = null;
      this.menu.parentMenuName = null;
      this.menu.path = null;
    },
    saveMenu() {
      this.$refs['menu'].validate((valid) => {
        if (valid) {
          const params = {
            parentMenuId: this.menu.parentMenuId === "" ? null : this.menu.parentMenuId,
            menuId: this.menu.menuId,
            name: this.menu.name,
            path: this.menu.path,
            sort: this.menu.sort
          };
          saveMenu(params).then(res => {
            if (res.code == 0) {
              this.dialogFormVisible = false
              this.getBackStageMenu();
            }
          }).catch(error => console.error(error));
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    updateMenu() {
      this.$refs['backStageMenu'].validate((valid) => {
        if (valid) {
          const params = {
            parentMenuId: this.backStageMenu.parentMenuId,
            menuId: this.backStageMenu.menuId,
            name: this.backStageMenu.name,
            path: this.backStageMenu.path,
            sort: this.backStageMenu.sort
          };
          updateMenu(params).then(res => {
            if (res.code == 0) {
              this.dialogFormVisible = false
              this.getBackStageMenu();
            }
          }).catch(error => console.error(error));
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    removeMenu() {
      this.$confirm('此操作将删除该节点, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          menuId: this.backStageMenu.menuId,
        };
        removeMenu(params).then(res => {
          if (res.code == 0) {
            this.dialogFormVisible = false;
            this.showFlag = false;
            this.getBackStageMenu();
          }
        }).catch(error => console.error(error));
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    getBackStageMenu() {
      const params = {};
      getBackStageMenu(params).then(res => {
        if (res.code == 0) {
          this.backStageMenu = {};
          this.data = res.data;
          this.$refs.tree.setCheckedKeys([]);
          this.showFlag = false;
        } else {
          this.$message.error(res.msg)
        }
      }).catch(error => console.error(error));
    }
  },
  created() {
    this.getBackStageMenu();
  }
}
</script>

<style scoped>
.tree-list {
  padding: 30px;
  height: auto;
  width: 40%;
  float: left;
  font-size: 12px;
}

.tree-detail {
  background-color: white;
  margin: 30px;
  padding: 50px 0;
  height: auto;
  width: 50%;
  float: left;
  text-align: center;
}

.el-dialog-div {
  height: 60vh;
  overflow: auto;
}
</style>
