<template>
    <div>
        <div class="container" style="margin: 20px">
            <div class="handle-box">
                <!--        <el-input v-model="query.keyword" placeholder="考试名" class="handle-input mr10"></el-input>-->
                <!--        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>-->
                <el-select v-model="query.solve" class="mr10" style="vertical-align:top;margin-left: 10px"
                           @change="changeDel">
                    <el-option :value="0" label="未处理"></el-option>
                    <el-option :value='1' label="已处理"></el-option>
                </el-select>
                <!--<el-button type="primary" icon="el-icon-plus" @click="add">添加考试</el-button>-->
            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
            >
                <el-table-column prop="id" label="ID" width="55" align="center" v-if="false"></el-table-column>
                <el-table-column prop="userId" label="用户"></el-table-column>
                <el-table-column prop="questionId" label="问题id"></el-table-column>
                <el-table-column prop="content" label="内容"></el-table-column>
                <el-table-column prop="detailName" label="科目"></el-table-column>
                <el-table-column prop="year" label="年份"></el-table-column>
                <el-table-column prop="no" label="题号"></el-table-column>
                <el-table-column prop="type" label="类型" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        {{
                        scope.row.type === '0' ? '图片缺失' : scope.row.type === '1' ? '答案错误' : scope.row.type === '2' ? '解析错误' : scope.row.type === '3' ? '文字错误' : '其他'
                        }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="280" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-edit"
                                class="success"
                                @click="handleEdit(scope.$index, scope.row)"
                                v-if="scope.row.delFlag === '0'"
                        >处理
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="prev, pager, next, jumper"
                        :current-page="query.current"
                        :page-size="query.size"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <!-- 添加或编辑 -->
        <el-dialog title="处理" :visible.sync="editVisible" width="1200px">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="题目来源" class="required">
                    <span>{{ detail.detail + ' ' + detail.year + ' ' + detail.no + ' 第' + detail.takeUp + '个空'}}</span>
                </el-form-item>
                <el-form-item label="反馈内容" class="required">
                    <span>{{ detail.content }}</span>
                </el-form-item>
                <el-form-item label="反馈类型" class="required">
                    <span>{{ detail.type === '0' ? '图片缺失' : detail.type === '1' ? '答案错误' : detail.type === '2' ? '解析错误' : detail.type === '3' ? '文字错误' : '其他' }}</span>
                </el-form-item>
                <el-form-item label="题干" class="required">
                    <el-input type="textarea" v-model="detail.name"></el-input>
                </el-form-item>
                <el-form-item label="解析" style="margin: 20px 0 0 0;">
                    <el-input type="textarea" v-model="detail.parse"></el-input>
                </el-form-item>
                <div v-if="detail.answers !== null && detail.answers.length >0">
                    <el-form-item :label="'答案' + (index + 1)" style="margin: 20px 0 0 0;"
                                  v-for="(item, index) in detail.answers" v-bind:key="index">
                        <el-input v-model="item.name"></el-input>
                    </el-form-item>
                    <el-form-item label="正确答案" style="margin: 20px 0 0 0;text-align: left">
                        <el-radio-group v-model="rightIndex">
                            <el-radio :label="0">A</el-radio>
                            <el-radio :label="1">B</el-radio>
                            <el-radio :label="2">C</el-radio>
                            <el-radio :label="3">D</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>
                <el-form-item label="快捷回复" style="margin: 20px 0 0 0;text-align: left">
                    <el-radio-group v-model="detail.reply">
                        <el-radio label="感谢反馈"></el-radio>
                        <el-radio label="感谢反馈，已更正"></el-radio>
                        <el-radio label="感谢反馈，已确认答案无误"></el-radio>
                        <el-radio label="感谢反馈，未发现图片缺失问题"></el-radio>
                        <el-radio label="感谢反馈，未发现文字错误"></el-radio>
                        <el-radio label="感谢反馈，已补充图片"></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="回复内容" style="margin: 20px 0 0 0;">
                    <el-input type="textarea" v-model="detail.reply"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveAdd">确 定</el-button>
            </span>
        </el-dialog>


        <!-- 获取考试等级分类 -->
        <el-dialog title="考试级别" :visible.sync="examTypeTableVisible" width="80%">
            <el-button type="primary" icon="el-icon-plus" @click="handleAdd"
                       style="margin: -10px    auto 20px auto;display: block">添加考试
            </el-button>
            <el-table
                    :data="examType"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
                    :default-sort="{prop:'sort'}"
            >
                <el-table-column prop="typeName" label="考试级别名"></el-table-column>
                <el-table-column prop="sort" label="排序" sortable></el-table-column>
                <el-table-column prop="createDate" label="创建时间"></el-table-column>
                <el-table-column label="操作" width="100" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-delete"
                                class="red"
                                @click="contentDelete(scope.$index, scope.row)"
                        >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="prev, pager, next, jumper"
                        :current-page="queryExamTypeTable.current"
                        :page-size="queryExamTypeTable.size"
                        :total="pageTotal2"
                        @current-change="handlePageChange2"
                ></el-pagination>
            </div>
        </el-dialog>

        <!-- 获取考试等级分类 -->
        <el-dialog title="考试级别" :visible.sync="examTypeAddVisible" width="40%">
            <el-form ref="form" :model="form" label-width="150px">
                <el-form-item label="考试级别名" class="required" required style="width: 80%">
                    <el-input v-model="addExamType.typeName">
                    </el-input>
                </el-form-item>
                <el-form-item label="排序" style="width: 80%;margin: 20px 0 0 0;">
                    <el-input v-model="addExamType.sort"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="examTypeAddVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveExamType">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

import {
    getErrorDetail,
    getErrorPage,
    getExamTypeList, handleError,
    removeOrRenewExam,
    saveExam, saveExamType,
    updateExam
} from "@/api/backStage/exam";

export default {
    name: 'filmTable',
    data() {
        return {
            query: {
                current: 1,
                size: 10,
                keyword: '',
                delFlag: 0,
                solve: 0
            },
            queryExamTypeTable: {
                current: 1,
                size: 10,
                keyword: '',
                delFlag: 0
            },
            rightIndex: 0,
            detail: {
                answers: [],
                content: '',
                detail: '',
                id: '',
                name: '',
                no: '',
                parse: '',
                questionId: '',
                takeUp: '',
                type: '',
                year: '',
                reply: '',
            },
            tableData: [],
            pageTotal: 1,
            pageTotal2: 1,
            loading: false,
            form: {},
            //新增
            addData: {
                id: '',
                name: '',
                description: '',
                shortDescription: '',
                image: '',
                type: ''
            },
            examType: [],
            editVisible: false,
            addVisible: false,
            examTypeTableVisible: false,
            examTypeAddVisible: false,
            addExamType: {
                id: '',
                examId: '',
                typeName: '',
                sort: ''
            },
            delList: [],
        };
    },
    dicts: ['EXAM'],
    created() {
        this.getData();
    },
    methods: {
        // 获取 easy-mock 的模拟数据
        getData() {
            const data = {
                keyword: this.query.keyword,
                current: this.query.current,
                size: this.query.size,
                delFlag: this.query.delFlag,
                solve: this.query.solve
            }
            getErrorPage(data).then(res => {
                this.tableData = res.data.records;
                this.pageTotal = res.data.total;
                //console.log(this.tableData)
            })
        },

        changeType(value) {
            // console.log(value);
            this.query.type = value;
            this.getData();
        },
        changeDel(value) {
            // console.log(value);
            this.query.delFlag = value;
            this.getData()
        },
        // 触发搜索按钮
        handleSearch() {
            this.$set(this.query, 'pageIndex', 1);
            this.getData();
        },
        // 新增按钮
        add() {
            this.addData.id = '';
            this.addData.name = '';
            this.addData.description = '';
            this.addData.shortDescription = '';
            this.addData.image = '';
            this.addData.type = '';
            this.editVisible = true
        },
        // 处理
        saveAdd() {
            for (var i = 0; i < this.detail.answers.length; i++) {
                if (i === this.rightIndex) {
                    this.detail.answers[i].correct = 0;
                } else {
                    this.detail.answers[i].correct = 1;
                }
            }
            console.log(this.detail)
            handleError(this.detail).then(res => {
                if (res.code === 0) {
                    this.editVisible = false;
                    this.getData();
                }
            })
        },
        // 删除操作
        handleDelete(index, row) {
            // console.log(row)
            // 二次确认删除
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning'
            })
                .then(() => {
                    const data = {
                        id: row.id,
                        delFlag: row.delFlag
                    }
                    removeOrRenewExam(data).then(() => {
                        this.getData()
                    });
                })
                .catch(() => {
                });
        },
        contentDelete(index, row) {
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning'
            })
                .then(() => {
                    const data = {
                        softwareContentId: row.id,
                        fileId: row.fileId,
                        url: row.url
                    }
                    removeOrRenewExam(data).then(() => {
                        this.getData()
                    });
                })
                .catch(() => {
                });
        },
        // 编辑操作
        handleEdit(index, row) {
            this.editVisible = true;
            getErrorDetail({id: row.id}).then(res => {
                this.detail = res.data;
                if (this.detail.answers != null && this.detail.answers.length > 0) {
                    for (var i = 0; i < this.detail.answers.length; i++) {
                        if (this.detail.answers[i].correct === 0) {
                            //console.log(i);
                            this.rightIndex = i;
                        }
                    }
                }
                //console.log(res)
            })
        },
        getExamTypeTable(index, row) {
            this.examTypeTableVisible = true;
            //保存当前打开的考试
            if (row != undefined) {
                this.addExamType.examId = row.id
            }
            const data = {
                examId: row.id,
                current: this.queryExamTypeTable.current,
                size: this.queryExamTypeTable.size,
                delFlag: this.queryExamTypeTable.delFlag,
                keyword: this.queryExamTypeTable.keyword
            }
            getExamTypeList(data).then(res => {
                this.examType = res.data.records;
                this.pageTotal2 = res.data.total;
            })
        },
        handleAdd() {
            this.addExamType.id = '';
            this.addExamType.typeName = '';
            this.addExamType.sort = '';
            this.examTypeAddVisible = true
        },
        saveExamType() {
            // 保存考试等级分类
            const data = {
                examId: this.addExamType.examId,
                typeName: this.addExamType.typeName,
                sort: this.addExamType.sort
            }
            saveExamType(data).then(() => {
                this.examTypeAddVisible = false;
            })
        },
        // 分页导航
        handlePageChange(val) {
            this.$set(this.query, 'current', val);
            this.getData();
        },
        handlePageChange2(val) {
            this.$set(this.queryExamTypeTable, 'current', val);
            this.getExamTypeTable();
        }
    }
};
</script>

<style scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}

.table {
    width: 100%;
    font-size: 14px;
}

.red {
    color: #ff0000;
}

.mr10 {
    margin-right: 10px;
}

.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>
