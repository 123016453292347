<template>
  <div>
    <div class="container" style="margin: 20px">
      <div class="handle-box">
        <el-input v-model="query.keyword" placeholder="考试名" class="handle-input mr10"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        <el-select v-model="query.delFlag" class="mr10" style="vertical-align:top;margin-left: 10px"
                   @change="changeDel">
          <el-option :value="0" label="正常考试"></el-option>
          <el-option :value='1' label="已删除考试"></el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-plus" @click="add">添加考试</el-button>
      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="id" label="ID" width="55" align="center" v-if="false"></el-table-column>
        <el-table-column prop="name" label="考试名"></el-table-column>
        <el-table-column prop="description" label="描述" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="shortDescription" label="简述" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="image" label="封面图片地址" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">
            {{ scope.row.type | dickFilter('EXAM') }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="280" align="center">
          <template slot-scope="scope">
            <el-button
                type="text"
                icon="el-icon-s-operation"
                class="success"
                @click="getExamTypeTable(scope.$index, scope.row)"
                v-if="scope.row.delFlag === '0'"
            >管理考试级别
            </el-button>
            <el-button
                type="text"
                icon="el-icon-edit"
                class="success"
                @click="handleEdit(scope.$index, scope.row)"
                v-if="scope.row.delFlag === '0'"
            >编辑
            </el-button>
            <el-button
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="handleDelete(scope.$index, scope.row)"
            >{{ scope.row.delFlag === '0' ? '删除' : '恢复' }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="prev, pager, next, jumper"
            :current-page="query.current"
            :page-size="query.size"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 添加或编辑 -->
    <el-dialog title="添加考试" :visible.sync="editVisible" width="580px">
      <el-form ref="form" :model="form" label-width="80px">
        <el-input v-if="false" v-model="addData.id"></el-input>
        <el-form-item label="考试名" class="required" required style="width: 500px">
          <el-input v-model="addData.name">
          </el-input>
        </el-form-item>
        <el-form-item label="考试描述" style="width: 500px;margin: 20px 0 0 0;">
          <el-input type="textarea" v-model="addData.description"></el-input>
        </el-form-item>
        <el-form-item label="考试简介" style="width: 500px;margin: 20px 0 0 0;">
          <el-input v-model="addData.shortDescription"></el-input>
        </el-form-item>
        <el-form-item label="图片地址" style="width: 500px;margin: 20px 0 0 0;">
          <el-input v-model="addData.image"></el-input>
        </el-form-item>
        <el-form-item label="类型" style="width: 500px;margin: 20px 0 0 0;">
          <el-select v-model="addData.type" filterable style="width: 420px">
            <el-option v-for="(data, key) in this.dict.EXAM" :key="key" :label="data" :value="key"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveAdd">确 定</el-button>
            </span>
    </el-dialog>


    <!-- 获取考试等级分类 -->
    <el-dialog title="考试级别" :visible.sync="examTypeTableVisible" width="80%">
      <el-button type="primary" icon="el-icon-plus" @click="handleAdd"
                 style="margin: -10px    auto 20px auto;display: block">添加考试
      </el-button>
      <el-table
          :data="examType"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          :default-sort="{prop:'sort'}"
      >
        <el-table-column prop="typeName" label="考试级别名"></el-table-column>
        <el-table-column prop="sort" label="排序" sortable></el-table-column>
        <el-table-column prop="createDate" label="创建时间"></el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="contentDelete(scope.$index, scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="prev, pager, next, jumper"
            :current-page="queryExamTypeTable.current"
            :page-size="queryExamTypeTable.size"
            :total="pageTotal2"
            @current-change="handlePageChange2"
        ></el-pagination>
      </div>
    </el-dialog>

    <!-- 获取考试等级分类 -->
    <el-dialog title="考试级别" :visible.sync="examTypeAddVisible" width="40%">
      <el-form ref="form" :model="form" label-width="150px">
        <el-form-item label="考试级别名" class="required" required style="width: 80%">
          <el-input v-model="addExamType.typeName">
          </el-input>
        </el-form-item>
        <el-form-item label="排序" style="width: 80%;margin: 20px 0 0 0;">
          <el-input v-model="addExamType.sort"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
                <el-button @click="examTypeAddVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveExamType">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>

import {
  getExamList,
  getExamTypeList,
  removeOrRenewExam,
  saveExam, saveExamType,
  updateExam
} from "@/api/backStage/exam";

export default {
  name: 'filmTable',
  data() {
    return {
      query: {
        current: 1,
        size: 10,
        keyword: '',
        delFlag: 0
      },
      queryExamTypeTable: {
        current: 1,
        size: 10,
        keyword: '',
        delFlag: 0
      },
      tableData: [],
      pageTotal: 1,
      pageTotal2: 1,
      loading: false,
      form: {},
      //新增
      addData: {
        id: '',
        name: '',
        description: '',
        shortDescription: '',
        image: '',
        type: ''
      },
      examType: [],
      editVisible: false,
      addVisible: false,
      examTypeTableVisible: false,
      examTypeAddVisible: false,
      addExamType: {
        id:'',
        examId:'',
        typeName:'',
        sort:''
      },
      delList: [],
    };
  },
  dicts: ['EXAM'],
  created() {
    this.getData();
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    getData() {
      const data = {
        keyword: this.query.keyword,
        current: this.query.current,
        size: this.query.size,
        delFlag: this.query.delFlag
      }
      getExamList(data).then(res => {
        this.tableData = res.data.records;
        this.pageTotal = res.data.total;
      })
    },

    changeType(value) {
      // console.log(value);
      this.query.type = value;
      this.getData();
    },
    changeDel(value) {
      // console.log(value);
      this.query.delFlag = value;
      this.getData()
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, 'pageIndex', 1);
      this.getData();
    },
    // 新增按钮
    add() {
      this.addData.id = '';
      this.addData.name = '';
      this.addData.description = '';
      this.addData.shortDescription = '';
      this.addData.image = '';
      this.addData.type = '';
      this.editVisible = true
    },
    // 保存编辑
    saveAdd() {
      //如果id为空走保存，如果id不为空，走编辑
      if (this.addData.id === '') {
        saveExam(this.addData).then(() => {
          this.getData();
          this.editVisible = false;
        })
      } else {
        updateExam(this.addData).then(() => {
          this.getData();
          this.editVisible = false;
        })
      }

    },
    // 删除操作
    handleDelete(index, row) {
      // console.log(row)
      // 二次确认删除
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      })
          .then(() => {
            const data = {
              id: row.id,
              delFlag: row.delFlag
            }
            removeOrRenewExam(data).then(() => {
              this.getData()
            });
          })
          .catch(() => {
          });
    },
    contentDelete(index, row) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      })
          .then(() => {
            const data = {
              softwareContentId: row.id,
              fileId: row.fileId,
              url: row.url
            }
            removeOrRenewExam(data).then(() => {this.getData()});
          })
          .catch(() => {
          });
    },
    // 编辑操作
    handleEdit(index, row) {
      this.addData.id = row.id;
      this.addData.name = row.name;
      this.addData.description = row.description;
      this.addData.shortDescription = row.shortDescription;
      this.addData.image = row.image;
      this.addData.type = row.type;
      this.editVisible = true;
    },
    getExamTypeTable(index, row) {
      this.examTypeTableVisible = true;
      //保存当前打开的考试
      if (row != undefined){
        this.addExamType.examId = row.id
      }
      const data = {
        examId: row.id,
        current: this.queryExamTypeTable.current,
        size: this.queryExamTypeTable.size,
        delFlag: this.queryExamTypeTable.delFlag,
        keyword: this.queryExamTypeTable.keyword
      }
      getExamTypeList(data).then(res => {
        this.examType = res.data.records;
        this.pageTotal2 = res.data.total;
      })
    },
    handleAdd() {
      this.addExamType.id = '';
      this.addExamType.typeName = '';
      this.addExamType.sort = '';
      this.examTypeAddVisible = true
    },
    saveExamType() {
      // 保存考试等级分类
      const data = {
        examId: this.addExamType.examId,
        typeName: this.addExamType.typeName,
        sort: this.addExamType.sort
      }
      saveExamType(data).then(() => {
        this.examTypeAddVisible = false;
      })
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, 'current', val);
      this.getData();
    },
    handlePageChange2(val) {
      this.$set(this.queryExamTypeTable, 'current', val);
      this.getExamTypeTable();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
