import Request from '@/utils/request';

// 获取工具
export const getToolList = (params) => Request.get('/chick/tools/list', { data: { ...params }, loading: false });
// 保存工具
export const saveDB = (params) => Request.post('/chick/dictionary/save', { data: { ...params }, loading: false });
// 更新工具
export const updateDB = (params) => Request.post('/chick/dictionary/update', { data: { ...params }, loading: false });
// 删除工具
export const removeOrRenewTool = (params) => Request.post('/chick/tools/deleteOrRenew', { data: { ...params }, loading: false });
