<template>
  <div class="toolsTitle" v-loading="loading" style="height: auto;min-height: 900px;">
    <div style="width: 80%;margin: 0 auto;">
      <div style="float: left;height: 250px;width: 15%;">
        <div style="height: auto;width: 40%;float: left;margin-bottom: 15px;margin: 20px">
          <div style="margin: 0 auto">
            <el-image :src="novel.imageUrl" style="width: 140px;height: 200px;margin: 0 10px 0 0">
              <div slot="error" class="image-slot">
                <el-image :src="'https://www.qb5.tw/modules/article/images/nocover.jpg'"
                          style="width: 140px;height: 200px;margin: 0 10px 0 0"></el-image>
              </div>
            </el-image>
          </div>
        </div>
      </div>
      <div style="float: left;height: 250px;width: 80%;text-align: left;">
        <div style="margin: 20px;margin-left: 0">
          <div style="margin-bottom: 10px">
            书名：<span>{{ novel.name }}</span>
          </div>
          <div style="margin-bottom: 10px">
            作者：<span>{{ novel.author }}</span>
          </div>
          <div style="margin-bottom: 10px">
            来源：<span>{{ novel.source }}</span>
          </div>
          <div style="margin-bottom: 10px">
            描述：<span>{{ novel.describtion }}</span>
          </div>
        </div>
      </div>
    </div>

    <div style="width: 80%;margin: 0 auto">
      <el-row :gutter="20">
        <el-col :span="6" v-for="(item,index) in novel.novelChapters" v-bind:key="index">
          <router-link :to="{path:'/read',query:{bookId:item.indexUrl.split('-')[0],chapterId:item.indexUrl.split('-')[1]}}" style="text-decoration: none;">
            <div class="grid-content" style="text-align: left">
              {{ item.name | nameFilter() }}
            </div>
          </router-link>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {getNovelChapterThirdPartList} from "@/api/frontStage/novel";

export default {
  name: "ChapterIndex",
  data() {
    return {
      loading: true,
      novelList: [],
      pageTotal: 1,
      novel: ''
    }
  },
  methods: {
    getData() {
      this.loading = true;
      const data = {
        bookId: this.$route.query.id
      }
      getNovelChapterThirdPartList(data).then(res => {
        this.novel = res.data;
        this.loading = false;
      })
    },
  },
  mounted() {
    this.getData();
  },
  computed: {},
  filters: {
    nameFilter(val) {
      if (val.length > 18) {
        return val.substr(0, 17) + '...'
      }
      return val;
    }
  }
}
</script>

<style scoped>
.toolsTitle {
  text-align: center;
}

.link > a:hover {
  color: dodgerblue;
}

.el-row {
  margin-bottom: 20px;

&
:last-child {
  margin-bottom: 0;
}

}
.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.link > a:hover {
  color: dodgerblue;
}
router-link {
  text-decoration: none;
}
</style>
