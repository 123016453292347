<template>
  <div class="UUIDContent">
    <div class="tip" style="text-align: left">
      二维码生成
    </div>
    <div>
      <span style="font-size: 27px;margin: 0 20px 0 0;vertical-align:top">二维码内容:</span>
      <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入生成内容"
          v-model="textarea"
          style="width: 60%;padding-bottom: 20px"
      >
      </el-input>
    </div>
    <el-button @click="generate" type="primary">生成二维码</el-button>
    <el-button @click="getDownload" type="primary">下载二维码</el-button>
    <div v-if="base64 !== ''">
      <el-image :src="base64" style="max-height: 400px;max-width: 500px"></el-image>
    </div>
  </div>
</template>

<script>
import {createQRCode} from '@/api/frontStage/tools'
import {Download} from "@/utils/download";

export default {
  name: "UUID",
  data() {
    return {
      textarea: '',
      base64: '',
      download: new Download()
    }
  },
  methods: {
    generate() {
      createQRCode({textarea: this.textarea}).then(res => {
        this.base64 = res.data;
      })
    },
    getDownload() {
      this.download.downloadFile('二维码.jpg', this.base64)
    }
  }
}
</script>

<style scoped>
.UUIDContent {
  min-height: 500px;
  height: auto;
  width: 100%;
  background-color: white;
}

.tip {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 0 0 20px 0;
  font-size: 40px;
}

</style>
