<template>
<div class="main">
  <el-empty style="width: auto;margin: 0 auto;min-height: 65vh" description="暂无内容"></el-empty>

</div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      data: "&nbsp;&nbsp;&nbsp;&nbsp;遗迹大陆，叶伏天自黑暗世界的通道中回到这片大陆，便看到下空之地到处都是战场，和他离开之前仿佛是两个世界。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;不过这也正常，从之前掠夺遗迹之战到进入和平时期，各自修行，无数人修为进步，破境蜕变，但更多的人什么都没有得到，在这种背景下，实则战争一直都处在酝酿之中。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;如今，黑暗神君的命令，使得黑暗世界的修行之人点燃了这片战场，使得无数修行之人内心中压抑已久的情绪凶猛的爆发出来。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;他身形加快速度赶路，在战斗爆发之时他已经知道了，下令让叶帝宫的修行之人不得轻举妄动参战，他自己被困黑暗神庭，若是这里参战出现意外会非常麻烦。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;更何况，叶帝宫没有大帝，他们还缺少一些底气。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;其实黑暗神君所言有些道理，包括之前司君的一些话虽然不好听，但是背后的确是那么回事，他能够活到现在，有先生的原因，干涉了东凰大帝，其次，是黑暗神庭和空山神那边都有意任由他成长强大，任由他成为神州之敌。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;再者，人间界暂时和他没有恩怨，西天佛门佛祖对他还是颇为善意的，是否如同黑暗神君所说的伪善，他目前无法得知，但至少现在来看，他没有感受到。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;这种背景下，他实则是夹缝中生存，但这种情形是否是因为执棋子的人所造成的，那么便不得而知了。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;叶伏天回到了叶帝宫中，直奔最高的宫殿而去，得知叶伏天回来，叶帝宫的强者都顺着阶梯往上，朝着那边汇聚而去，很快，叶帝宫的核心之人大多都到了，聚集在宫殿之外。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;花解语也从宫殿中走出，来到了叶伏天身旁，玲珑则是安静的站在他身后，西池瑶走向叶伏天，在他身前不远处停下脚步，笑着问道：“你胆子真大，黑暗神庭都敢前往。”\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;他们对黑暗神庭都有所耳闻，黑暗神庭的君主是暴君，执掌黑暗之人，谁知道他会做出什么事情来，叶伏天此行太过冒险了些。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;“这不是平安归来了吗。”叶伏天不在意的笑道，此行虽然遇到了一些麻烦，但实则还算顺利，算是一场经历，对他而言有一些意义，无论是在奇迹之岛所遇到的圣湖女子还是黑暗神君对他所说的一席话，都对他有些影响。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;“你便不担心那暴君一怒之下将你永远留在那，叶帝宫这边怎么办？”西池瑶似乎对此有些不满，她认为叶伏天此行过于任性冲动了。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;虽然她明白叶伏天重情义，但叶青瑶毕竟是黑暗神庭修行之人，他无法左右叶青瑶的命运，终究还是黑暗神君来决定的，即便他真能改变什么，为了叶青瑶便让叶帝宫深陷危机之中，感性上可以理解，但理性去看待的话，当然是不可取的行为。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;当然，她也并非真的生气，若他不去，便就不是他了。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;或许正因为如此，他身边才会聚集这么多的优秀之人，心甘情愿的追随左右吧，其中许多人还都是在叶伏天弱小之时作为他的长辈便跟随他的。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;“以后注意。”叶伏天听到西池瑶的质问苦笑着摇头。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;旁边，花解语微笑的看着这一幕。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;“咳咳！”西帝宫的老宫主咳嗽了一声，顿时西池瑶神色也变得有些古怪，开口道：“作为盟友，且执掌叶帝宫中的西帝宫一方势力，我有必要提醒叶宫主以后行事多为大局考虑。”\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;周围的人都看着她，许多人都偷偷的笑看了一眼西池瑶和叶伏天，这是心虚了吗？\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;他们这宫主，还真是厉害，不佩服不行。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;听说，之前在某处神之禁地，和东凰帝鸳也发生了点故事，崇拜。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;“好。”叶伏天点头，他看向诸人，陡然间严肃了起来，问道：“外界如今怎么样了？”\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;此言将话题引开来，避免了刚才尴尬的局面，诸人也都没有在纠结这点小事，毕竟宫主夫人还在呢，这事如何轮得到他们操心，即便宫主要纳妾，也是夫人考虑的事。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;西池瑶也是极其出众的女子，大帝后裔，但他们并不认为纳为妾氏有什么不妥，毕竟，那可是他们宫主叶伏天，纳妾有什么？\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;将来宫主成帝之后，便是帝妃了，世所瞩目。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;“战火连天，六界势力尽皆卷入其中，而且已经不是统一战斗，六界势力各自为战，帝宫之间也同样冲突不断，其中最为激烈的便是黑暗神庭以及东凰帝宫，不久前双方爆发了一场大战，而且未来还会继续，这场战斗有可能会彻底引爆六界积蓄已久的恩怨，爆发一场超越四百多年前的乱战。”太上剑尊开口说道，这场战斗的风暴愈演愈烈，已经有控制不住的局势了。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;更何况，六界势力，也都没有想要去控制这局面。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;或许，这遗迹大陆的出现成为了一个契机，战争之契机，这里有许多机缘，有许多大帝留下的传承，是一片独立的大陆，适合成为战场。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;这场变局，将影响六界之格局，甚至诞生一些超凡之人，只是不知道是否会有大帝人物问世。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;“恩。”叶伏天点头：“天界有没有动静？”\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;“没有。”太上剑尊摇头：“没听说天界参战，当年他们离开古天庭之后便没了踪迹，和以前一样低调。”\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;叶伏天却是皱了皱眉，天界是想要坐收渔利吧，那姬无道，是非常危险之人，这一点他在禁地之中便感受过了，此人，吞噬了不少遗迹传承，他的潜力也绝对是超级可怕的。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;“外界还传出一则消息。”太上剑尊又道。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;“什么消息？”叶伏天询问道。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;“现在还只是一些小道传闻，不能确定，这消息是从人间界的修行强者口中传出的，据说，人间界人祖，有意和神州联姻，有可能代表他的弟子帝昊，向东凰大帝提亲。”太上剑尊说道。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;叶伏天瞳孔收缩，他不确定当年究竟发生了什么事情，但若这则传闻是真的，这背后用意绝对不那么简单，尤其是联想到黑暗神君的话，人祖当年也可能参与了那件事。\n" +
          "<br>\n" +
          "<br> &nbsp;&nbsp;&nbsp;&nbsp;那么这提亲，背后隐藏着怎样的用意！\n" +
          "\n" +
          "Process finished with exit code 0\n"
    }
  },
  methods: {}
}
</script>

<style scoped>
.main {
  height: auto;
  min-height: 900px;
  width: 100%;
}
</style>
