<template>
<div class="main">
  <div style="width: 50%;float: left;height: 1000px;background-image: linear-gradient(to right, white , rgb(235,234,243));position:relative;">
    <div style="position:absolute;">
      <div style="margin: 150px 0 0 350px;height: 200px;width: 600px" >
        <img src="../../../../public/167626283742999.png">
      </div>
      <div style="margin: 0 0 0 300px;height: 200px;width: 600px">
        <img src="../../../../public/167626279631534.png" >
      </div>
    </div>
  </div>
  <div style="width: 50%;float: left;height: 1000px;background-color: rgb(235,234,243);">
    <video ref="myVideo" style="height: auto;width: 80%;margin: 100px 0 0 0;line-height: 800px" muted controlsList="nodownload">
      <source src="../../../../public/17.mp4" type="video/mp4">
    </video>
  </div>
  <div>
    <img src="../../../../public/0c1c9e7ade8dbac90130a5c54ed43797.jpg">
  </div>
</div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {}
  },
  methods: {
    play(){
      this.$refs.myVideo.play();
    }
  },
  mounted() {
    this.play();
    window.addEventListener("scroll", this.play, true);
  }
}
</script>

<style scoped>
* {
  height: 100%;
  width: 100%;
}
.main {
  height: auto;
  width: 100%;
  /*background-image: linear-gradient(to right, white , rgb(225,225,240));*/
  /*background-color: rgb(235,234,243);*/
  /*text-align: right;*/

}
</style>
