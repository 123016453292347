<template>
  <div>
    <div class="tree-list">
      <el-tree :data="data"
               :props="defaultProps"
               :check-strictly="true"
               show-checkbox
               :expand-on-click-node="false"
               ref="tree"
               node-key="roleId"
               @check="handleNodeChange">
      </el-tree>
    </div>
    <div class="tree-detail" v-if="showFlag">
      <el-col :offset="5" :span="12">
        <el-form ref="backStageRole" :model="backStageRole" label-width="80px" :rules="rules">
          <el-form-item label="角色名" prop="roleName">
            <el-input v-model="backStageRole.roleName"></el-input>
          </el-form-item>
          <el-form-item label="角色编码" prop="roleCode">
            <el-input v-model="backStageRole.roleCode"></el-input>
          </el-form-item>
          <el-form-item label="角色描述" >
            <el-input v-model="backStageRole.roleDesc"></el-input>
          </el-form-item>
        </el-form>
        <el-row>
          <el-button type="primary" @click="updateRole">保存角色</el-button>
          <el-button type="primary" @click="addRole">新增角色</el-button>
          <el-button type="danger" @click="removeRole">删除角色</el-button>
        </el-row>
      </el-col>
    </div>
    <div>
      <el-dialog title="增加节点" :visible.sync="dialogFormVisible" width="50%">
        <div style="height: 100%" class="el-dialog-div">
          <el-form :model="role" :rules="rules" ref="role">
            <el-col :offset="5" :span="12">
              <el-form-item label="角色名" prop="roleName">
                <el-input v-model="role.roleName"></el-input>
              </el-form-item>
              <el-form-item label="角色编码" prop="roleCode">
                <el-input v-model="role.roleCode"></el-input>
              </el-form-item>
              <el-form-item label="角色描述" >
                <el-input v-model="role.roleDesc"></el-input>
              </el-form-item>
            </el-col>
          </el-form>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveRole">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>


<script>
import {getRole, saveRole, updateRole, removeRole} from '@/api/backStage/system'

export default {
  data() {
    return {
      data: [],
      defaultProps: {
        children: 'children',
        label: 'roleName'
      },
      checkedId: {},
      showFlag: false,
      buttonFlag: false,
      backStageRole: {
        roleId: '',
        roleName: '',
        roleCode: '',
        roleDesc: ''
      },
      role: {
        roleId: '',
        roleName: '',
        roleCode: '',
        roleDesc: ''
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      rules: {
        roleName: [
          {required: true, message: '请输入角色名称', trigger: 'blur'},
        ],
        roleCode: [
          {required: true, message: '请输入角色编码', trigger: 'blur'},
        ]
      }
    };
  },
  components: {},
  methods: {
    handleNodeChange(data, checked) {
      this.showFlag = true;
      this.buttonFlag = false;
      this.backStageRole = data;
      if (checked) {
        this.$refs.tree.setCheckedKeys([data.roleId], true)
      }
    },
    addRole() {
      this.dialogFormVisible = true;
    },
    addObject() {
      this.dialogFormVisible = true;
    },
    updateRole() {
      this.$refs['backStageRole'].validate((valid) => {
        if (valid) {
          const params = {
            roleId: this.backStageRole.roleId,
            roleName: this.backStageRole.roleName,
            roleCode: this.backStageRole.roleCode,
            roleDesc: this.backStageRole.roleDesc
          };
          updateRole(params).then(res => {
            if (res.code == 0) {
              this.getRole();
            }
          }).catch(error => console.error(error));
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    saveRole() {
      this.$refs['role'].validate((valid) => {
        if (valid) {
          const params = {
            roleId: this.role.roleId,
            roleName: this.role.roleName,
            roleCode: this.role.roleCode,
            roleDesc: this.role.roleDesc
          };
          saveRole(params).then(res => {
            if (res.code == 0) {
              this.dialogFormVisible = false
              this.getRole();
            }
          }).catch(error => console.error(error));
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    removeRole() {
      this.$confirm('此操作将删除该节点, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          roleId: this.backStageRole.roleId,
        };
        removeRole(params).then(res => {
          if (res.code == 0) {
            this.dialogFormVisible = false;
            this.showFlag = false;
            this.getRole();
          }
        }).catch(error => console.error(error));
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    getRole() {
      const params = {};
      getRole(params).then(res => {
        if (res.code == 0) {
          this.data = res.data;
        } else {
          this.$message.error(res.msg)
        }
      }).catch(error => console.error(error));
    }
  },
  created() {
    this.getRole();
  }
}
</script>

<style scoped>
.tree-list {
  padding: 30px;
  height: auto;
  width: 40%;
  float: left;
  font-size: 12px;
}

.tree-detail {
  background-color: white;
  margin: 30px;
  padding: 50px 0;
  height: auto;
  width: 50%;
  float: left;
  text-align: center;
}

.el-dialog-div {
  height: 60vh;
  overflow: auto;
}
</style>
