<template>
<div class="main" v-loading="loading" style="text-align: left;width: 80%;margin: 0 auto">
  <div style="font-size: 30px;text-align: center;height: 80px;width: 100%"><h1 style="color: #2d8cf0">{{novel.title}}</h1></div>
  <div>
    <span v-html="novel.content" style="font-size: 20px"></span>
  </div>
  <div style="margin: 50px">
    <div style="width: auto;height: auto;text-align: center">
        <el-button style="margin-right: 50px" type="primary" @click="go(novel.bookId, novel.pre)">上一章</el-button>
        <el-button type="primary" @click="go(novel.bookId, novel.next)">下一章</el-button>
    </div>
  </div>

</div>
</template>

<script>
import {getNovelContentThirdPartList} from "@/api/frontStage/novel";

export default {
  name: "index",
  data() {
    return {
      novel:"",
      loading: true,
    }
  },
  methods: {
    getDate(){
      this.loading = true;
      const data = {
        bookId: this.$route.query.bookId,
        chapterId: this.$route.query.chapterId
      }
      getNovelContentThirdPartList(data).then(res => {
        console.log(res)
        this.novel = res.data;
        this.loading = false;
      })
    },
    go(bookId, chapterId){
      this.$router.push({path:'/read',query:{bookId:bookId,chapterId:chapterId}})
    }
  },
  mounted() {
    this.getDate();
  },
  watch:{
    $route(){
      this.getDate();
      document.documentElement.scrollTop = 0;
    }
  }
}
</script>

<style scoped>
.main {
  min-height: 900px;
  height: auto;
  width: 100%;
}
</style>
