import Request from '@/utils/request';

// 获取全部配置
export const getAllConfigInfo = (params) => Request.get('/chick/config/getAllConfigInfo', { data: { ...params }, loading: false });
// 保存配置，不带id
export const saveConfig = (params) => Request.post('/chick/config/save', { data: { ...params }, loading: false });
// 更新配置，带id
export const updateConfig = (params) => Request.post('/chick/config/update', { data: { ...params }, loading: false });
// 删除配置
export const removeConfig = (params) => Request.post('/chick/config/remove', { data: { ...params }, loading: false });
