<template>
  <div>
    <div class="container" style="margin: 20px">
      <div class="handle-box">
        <el-input v-model="query.keyword" placeholder="考试详情名" class="handle-input mr10"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        <!--考试名筛选-->
        <el-select v-model="query.examId" class="mr10" style="vertical-align:top;margin-left: 10px"
                   @change="changeExam" placeholder="考试名筛选">
          <el-option v-for="(item, index) in examList" :key="index" :value="item.id" :label="item.name"></el-option>
        </el-select>
        <!--考试等级筛选-->
        <el-select v-model="query.examTypeId" class="mr10" style="vertical-align:top;margin-left: 10px"
                   @change="getData" placeholder="考试等级筛选">
          <el-option v-for="(item, index) in examTypeList" :key="index" :value="item.id"
                     :label="item.typeName"></el-option>
        </el-select>

        <!--考试类型筛选-->
        <el-cascader
            v-model="query.types"
            :options="examTypeDb"
            :collapse-tags="true"
            :props="{ multiple: true, checkStrictly: true , label:'dataInfo', value:'dataNum', expandTrigger: 'hover'}"
            clearable
            @change="examTypeChange"
        ></el-cascader>

        <el-select v-model="query.delFlag" class="mr10" style="vertical-align:top;margin-left: 10px"
                   @change="getData">
          <el-option :value="0" label="正常考试详情"></el-option>
          <el-option :value='1' label="已删除考试详情"></el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-plus" @click="add">添加考试详情</el-button>
      </div>
      <el-table
          :data="tableData"
          border
          stripe
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="id" label="ID" width="55" align="center" v-if="false"></el-table-column>
        <el-table-column prop="detailName" label="考试名"></el-table-column>
        <el-table-column prop="code" label="考试编码"></el-table-column>
        <el-table-column prop="oneSelect" label="单选个数"></el-table-column>
        <el-table-column prop="multipleSelect" label="多选个数"></el-table-column>
        <el-table-column prop="judge" label="判断个数"></el-table-column>
        <el-table-column prop="fillBlank" label="填空个数"></el-table-column>
        <el-table-column prop="shortAnswer" label="简答个数"></el-table-column>
        <el-table-column label="所属考试">
          <template slot-scope="scope">
            {{getAffiliatedExam(scope.row.id)}}
          </template>
        </el-table-column>
        <el-table-column label="类型" :show-overflow-tooltip="true">
          <template slot-scope="scope" >
            {{ scope.row.type | dickFilter('EXAM') }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="280" align="center">
          <template slot-scope="scope">
            <el-button
                type="text"
                icon="el-icon-s-operation"
                class="success"
                @click="getExamSubjectTable(scope.$index, scope.row)"
                v-if="scope.row.delFlag === '0'"
            >管理考试科目
            </el-button>
            <el-button
                type="text"
                icon="el-icon-edit"
                class="success"
                @click="handleEdit(scope.$index, scope.row)"
                v-if="scope.row.delFlag === '0'"
            >编辑
            </el-button>
            <el-button
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="handleDelete(scope.$index, scope.row)"
            >{{ scope.row.delFlag === '0' ? '删除' : '恢复' }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="prev, pager, next, jumper"
            :current-page="query.current"
            :page-size="query.size"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 添加或编辑 -->
    <el-dialog title="添加考试详情" :visible.sync="editVisible" width="580px" top="1vh">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="考试详情名" class="required" required style="width: 500px">
          <el-input v-model="addData.detailName"></el-input>
        </el-form-item>
        <el-form-item label="所属考试" class="required" required style="width: 500px">
          <!--考试名筛选-->
          <el-select v-model="addData.examId" class="mr10" style="width: 370px;vertical-align:top;margin-left: 10px"
                     @change="changeExam" placeholder="考试名筛选">
            <el-option v-for="(item, index) in examList" :key="index" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试级别" class="required" required style="width: 500px">
          <!--考试等级筛选-->
          <el-select v-model="addData.examTypeId" class="mr10" style="width: 370px;vertical-align:top;margin-left: 10px"
                     placeholder="考试等级筛选">
            <el-option v-for="(item, index) in examTypeList" :key="index" :value="item.id"
                       :label="item.typeName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否开放" style="width: 500px;margin: 20px 0 0 0;" required>
          <el-switch
              v-model="addData.open"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="0"
              :inactive-value="1"
              style="float: left;margin: 10px 0 0 10px">
          </el-switch>
        </el-form-item>
        <el-form-item label="考试类型" style="width: 500px;margin: 20px 0 0 0;" required>
          <el-cascader
              v-model="addData.type"
              :options="examTypeDb"
              :collapse-tags="true"
              :props="{ multiple: false, checkStrictly: true , label:'dataInfo', value:'dataNum', expandTrigger: 'hover'}"
              clearable
              style="width: 370px"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="排序" style="width: 500px;margin: 20px 0 0 0;" required>
          <el-input v-model="addData.sort"></el-input>
        </el-form-item>
        <el-form-item label="考试编码" style="width: 500px;margin: 20px 0 0 0;" required>
          <el-input v-model="addData.code"></el-input>
        </el-form-item>
        <el-form-item label="单选个数" style="width: 500px;margin: 20px 0 0 0;" required>
          <el-slider
              v-model="addData.oneSelect"
              show-input
              :max="500">
          </el-slider>
        </el-form-item>
        <el-form-item label="多选个数" style="width: 500px;margin: 20px 0 0 0;" required>
          <el-slider
              v-model="addData.multipleSelect"
              show-input
              :max="500">
          </el-slider>
        </el-form-item>
        <el-form-item label="判断个数" style="width: 500px;margin: 20px 0 0 0;" required>
          <el-slider
              v-model="addData.judge"
              show-input
              :max="500">
          </el-slider>
        </el-form-item>
        <el-form-item label="填空个数" style="width: 500px;margin: 20px 0 0 0;" required>
          <el-slider
              v-model="addData.fillBlank"
              show-input
              :max="500">
          </el-slider>
        </el-form-item>
        <el-form-item label="简答个数" style="width: 500px;margin: 20px 0 0 0;" required>
          <el-slider
              v-model="addData.shortAnswer"
              show-input
              :max="500">
          </el-slider>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveAdd">确 定</el-button>
            </span>
    </el-dialog>


    <!-- 考试科目 -->
    <el-dialog title="考试科目" :visible.sync="examTypeTableVisible" width="80%" :close-on-click-modal="false">
      <el-button type="primary" icon="el-icon-plus" @click="handleAdd"
                 style="margin: -10px auto 20px auto;display: block">添加科目
      </el-button>
      <el-table
          :data="examSubject"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          :default-sort="{prop:'sort'}"
      >
        <el-table-column prop="name" label="科目名"></el-table-column>
        <el-table-column prop="sort" label="排序" sortable></el-table-column>
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button
                type="text"
                icon="el-icon-s-operation"
                class="success"
                @click="getExamQuestionTypeTable(scope.$index, scope.row)"
            >管理科目中的知识点
            </el-button>
            <el-button
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="contentDelete(scope.$index, scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="prev, pager, next, jumper"
            :current-page="queryExamSubjectTable.current"
            :page-size="queryExamSubjectTable.size"
            :total="pageTotal2"
            @current-change="handlePageChange2"
        ></el-pagination>
      </div>
    </el-dialog>

    <!-- 获取考试科目 -->
    <el-dialog title="考试科目" :visible.sync="examSubjectAddVisible" width="40%">
      <el-form ref="form" :model="form" label-width="150px">
        <el-form-item label="考试科目名" class="required" required style="width: 80%">
          <el-input v-model="addExamSubject.name">
          </el-input>
        </el-form-item>
        <el-form-item label="排序" style="width: 80%;margin: 20px 0 0 0;">
          <el-input v-model="addExamSubject.sort"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
                <el-button @click="examSubjectAddVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveExamDetail">确 定</el-button>
            </span>
    </el-dialog>


    <!-- 获取科目知识点 -->
    <el-dialog title="科目知识点" :visible.sync="examQuestionTableVisible" width="80%" :close-on-click-modal="false">
      <el-button type="primary" icon="el-icon-plus" @click="handleQuestionType"
                 style="margin: -10px auto 20px auto;display: block">添加知识点
      </el-button>
      <el-table
          :data="examQuestionType"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="name" label="科目名"></el-table-column>
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="contentDeleteQuestionType(scope.$index, scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="prev, pager, next, jumper"
            :current-page="queryExamQuestionTypeTable.current"
            :page-size="queryExamQuestionTypeTable.size"
            :total="pageTotal3"
            @current-change="handlePageChange3"
        ></el-pagination>
      </div>
    </el-dialog>

    <!-- 获取考试科目 -->
    <el-dialog title="知识点名" :visible.sync="examQuestionTypeAddVisible" width="40%">
      <el-form ref="form" :model="form" label-width="150px">
        <el-form-item label="知识点名" class="required" required style="width: 80%">
          <el-input v-model="addExamQuestionType.name">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
                <el-button @click="examQuestionTypeAddVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveExamQuestionType">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>

import {
  getChildrenDataForManager,
  getExamDetailByExamId,
  getExamDetailList, getExamListInExamDetail, getExamQuestionTypeByExamId, getExamSubjectList,
  removeOrRenewExamDetail, removeOrRenewExamQuestionType, removeOrRenewExamSubject,
  saveExamDetail, saveExamQuestionType, saveExamSubject,
  updateExamDetail
} from "@/api/backStage/exam";

export default {
  name: 'filmTable',
  data() {
    return {
      //查询参数
      query: {
        current: 1,
        size: 10,
        keyword: '',
        delFlag: 0,
        examId: '',
        examTypeId: '',
        types: '',
        typesStr: ''
      },
      // 考试详情数组
      tableData: [],
      examList: [],
      examTypeList: [],
      examTypeDb: [],
      addData: {
        id: '',
        examId: '',
        examTypeId: '',
        detailName: '',
        open: 1,
        type: [],
        typeStr: '',
        sort: '',
        code: '',
        oneSelect: '',
        multipleSelect: '',
        judge: '',
        fillBlank: '',
        shortAnswer: ''
      },
      examSubject: [],
      examQuestionType: [],
      editVisible: false,
      examTypeTableVisible: false,
      examSubjectAddVisible: false,
      examQuestionTableVisible: false,
      examQuestionTypeAddVisible: false,
      queryExamSubjectTable: {
        current: 1,
        size: 10,
        keyword: '',
        delFlag: 0
      },
      queryExamQuestionTypeTable: {
        current: 1,
        size: 6,
        keyword: '',
        delFlag: 0
      },
      pageTotal: 1,
      pageTotal2: 1,
      pageTotal3: 1,
      addExamSubject: {
        id: '',
        examId: '',
        detailId: '',
        name: '',
        sort: ''
      },
      addExamQuestionType:{
        id: '',
        examId: '',
        detailId: '',
        subjectId: '',
        name: ''
      },

      loading: false,
      form: {},

      delList: [],
    };
  },
  dicts: ['ALL-EXAM'],
  created() {
    this.getData();
    getChildrenDataForManager({key: 'EXAM', childrenOfChildren: true}).then(res => {
      //console.log(res.data[0].children)
      this.examTypeDb = res.data[0].children;
    })
    // 查询考试
    getExamListInExamDetail().then(res => {
      this.examList = res.data;
    })
  },
  methods: {
    getAffiliatedExam(id){
      for (let a in this.examList){
        this.examList[a].id === id;
        return this.examList[a].name;
      }
    },
    // getAffiliatedExamType(id){
    //   console.log(id)
    //   console.log(this.examTypeList)
    //   for (let a in this.examTypeList){
    //     this.examTypeList[a].examId === id;
    //     return this.examTypeList[a].typeName;
    //   }
    // },
    // 获取 easy-mock 的模拟数据
    getData() {
      const data = {
        keyword: this.query.keyword,
        current: this.query.current,
        size: this.query.size,
        delFlag: this.query.delFlag,
        examId: this.query.examId,
        examTypeId: this.query.examTypeId,
        types: this.query.typesStr
      }
      // 查询考试详情
      getExamDetailList(data).then(res => {
        this.tableData = res.data.records;
        this.pageTotal = res.data.total;
      })
    },
    changeExam(examId) {
      this.query.examTypeId = ''
      getExamDetailByExamId({examId: examId}).then(res => {
        this.examTypeList = res.data;
      })
      this.getData();
    },
    examTypeChange(examTypes) {
      this.query.typesStr = '';
      this.query.typesStr = this.getTypeStr(examTypes, this.query.typesStr);
      this.getData();
    },
    getTypeStr(examTypes, typeStrParam) {
      for (let a in examTypes) {
        let data = ((examTypes[a].constructor === Array) ? examTypes[a][examTypes[a].length - 1] : examTypes[a]);
        if (typeStrParam === '') {
          if (typeStrParam.split(',').indexOf(data) === -1) {
            typeStrParam = typeStrParam + data;
          }
        } else {
          if (typeStrParam.split(',').indexOf(data) === -1) {
            typeStrParam = typeStrParam + ',' + data;
          }
        }
      }
      return typeStrParam;
    },
    getTypeStr2(examTypes, typeStrParam) {
      let data = ((examTypes[0].constructor === Array) ? examTypes[0][examTypes[0].length - 1] : examTypes[0]);
      if (typeStrParam === '') {
        if (typeStrParam.split(',').indexOf(data) === -1) {
          typeStrParam = typeStrParam + data;
        }
      } else {
        if (typeStrParam.split(',').indexOf(data) === -1) {
          typeStrParam = typeStrParam + ',' + data;
        }
      }
      return typeStrParam;
    },
    // 保存编辑
    saveAdd() {
      const data = {
        id: this.addData.id,
        examId: this.addData.examId,
        examTypeId: this.addData.examTypeId,
        detailName: this.addData.detailName,
        open: this.addData.open,
        type: this.addData.type.constructor === String ? this.addData.type : this.getTypeStr2(this.addData.type, ''),
        sort: this.addData.sort,
        code: this.addData.code,
        oneSelect: this.addData.oneSelect,
        multipleSelect: this.addData.multipleSelect,
        judge: this.addData.judge,
        fillBlank: this.addData.fillBlank,
        shortAnswer: this.addData.shortAnswer
      }
      //如果id为空走保存，如果id不为空，走编辑
      if (this.addData.id === '') {
        saveExamDetail(data).then(res => {
          console.log(res);
          this.getData();
          this.editVisible = false;
        });
      } else {
        updateExamDetail(data).then(() => {
          this.getData();
          this.editVisible = false;
        })
      }
    },
    // 编辑操作
    handleEdit(index, row) {
      this.addData.id = row.id;
      this.addData.examId = row.examId;
      this.addData.examTypeId = row.examTypeId;
      this.addData.detailName = row.detailName;
      this.addData.open = row.open;
      this.addData.type = row.type;
      this.addData.typeStr = row.type;
      this.addData.sort = row.sort;
      this.addData.code = row.code;
      this.addData.oneSelect = row.oneSelect;
      this.addData.multipleSelect = row.multipleSelect;
      this.addData.judge = row.judge;
      this.addData.fillBlank = row.fillBlank;
      this.addData.shortAnswer = row.shortAnswer;
      this.editVisible = true;
    },
    // 新增按钮
    add() {
      this.addData.id = '';
      this.addData.examId = '';
      this.addData.examTypeId = '';
      this.addData.detailName = '';
      this.addData.open = 1;
      this.addData.type = '';
      this.addData.typeStr = '';
      this.addData.sort = '';
      this.addData.code = '';
      this.addData.oneSelect = '';
      this.addData.multipleSelect = '';
      this.addData.judge = '';
      this.addData.fillBlank = '';
      this.addData.shortAnswer = '';
      this.editVisible = true
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, 'pageIndex', 1);
      this.getData();
    },
    getExamSubjectTable(index, row) {
      this.examTypeTableVisible = true;
      //保存当前打开的考试
      if (row != undefined) {
        this.addExamSubject.detailId = row.id;
        this.addExamSubject.examId = row.examId;
      }
      const data = {
        detailId: this.addExamSubject.detailId,
        examId: this.addExamSubject.examId,
        current: this.queryExamSubjectTable.current,
        size: this.queryExamSubjectTable.size,
        delFlag: this.queryExamSubjectTable.delFlag,
        keyword: this.queryExamSubjectTable.keyword
      }
      getExamSubjectList(data).then(res => {
        this.examSubject = res.data.records;
        this.pageTotal2 = res.data.total;
      })
    },
    getExamQuestionTypeTable(index, row) {
      this.examQuestionTableVisible = true;
      //保存当前打开的考试
      if (row != undefined) {
        this.addExamQuestionType.subjectId = row.id;
        this.addExamQuestionType.examId = row.examId;
        this.addExamQuestionType.detailId = row.detailId;
      }
      const data = {
        detailId: this.addExamQuestionType.detailId,
        subjectId: this.addExamQuestionType.subjectId,
        examId: this.addExamQuestionType.examId,
        current: this.queryExamQuestionTypeTable.current,
        size: this.queryExamQuestionTypeTable.size,
        delFlag: this.queryExamQuestionTypeTable.delFlag,
        keyword: this.queryExamQuestionTypeTable.keyword
      }
      getExamQuestionTypeByExamId(data).then(res => {
        this.examQuestionType = res.data.records;
        this.pageTotal3 = res.data.total;
      })
    },
    handleAdd() {
      this.addExamSubject.id = '';
      this.addExamSubject.name = '';
      this.addExamSubject.sort = '';
      this.examSubjectAddVisible = true
    },
    handleQuestionType() {
      this.addExamQuestionType.name = '';
      this.examQuestionTypeAddVisible = true
    },
    changeDel(value) {
      this.query.delFlag = value;
      this.getData()
    },
    // 删除操作
    handleDelete(index, row) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      })
          .then(() => {
            const data = {
              id: row.id,
              delFlag: row.delFlag
            }
            removeOrRenewExamDetail(data).then(() => {
              this.getData()
            });
          })
          .catch(() => {
          });
    },
    contentDelete(index, row) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      })
          .then(() => {
            const data = {
              id: row.id,
            }
            removeOrRenewExamSubject(data).then(() => {
              this.getExamSubjectTable();
            });
          })
          .catch(() => {
          });
    },
    contentDeleteQuestionType(index, row) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      })
          .then(() => {
            const data = {
              id: row.id,
            }
            removeOrRenewExamQuestionType(data).then(() => {
              this.getExamQuestionTypeTable();
            });
          })
          .catch(() => {
          });
    },
    saveExamDetail() {
      // 保存考试等级分类
      const data = {
        id: this.addExamSubject.id,
        examId: this.addExamSubject.examId,
        detailId: this.addExamSubject.detailId,
        name: this.addExamSubject.name,
        sort: this.addExamSubject.sort
      }
      saveExamSubject(data).then(() => {
        this.examSubjectAddVisible = false;
        this.getExamSubjectTable();
      })
    },
    saveExamQuestionType(){
      // 保存知识点
      const data = {
        id: '',
        examId: this.addExamQuestionType.examId,
        subjectId :this.addExamQuestionType.subjectId,
        detailId: this.addExamQuestionType.detailId,
        name: this.addExamQuestionType.name
      }
      saveExamQuestionType(data).then(() => {
        this.examQuestionTableVisible = false;
        this.getExamQuestionTypeTable();
      })
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, 'current', val);
      this.getData();
    },
    handlePageChange2(val) {
      this.$set(this.queryExamSubjectTable, 'current', val);
      this.getExamSubjectTable();
    },
    handlePageChange3(val) {
      this.$set(this.queryExamQuestionTypeTable, 'current', val);
      this.getExamQuestionTypeTable();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

/*/deep/ .el-cascader__tags{*/
/*  height: 20px;*/
/*}*/
.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
