<template>
  <div class="myContent" v-loading="freightLoading"
       element-loading-text="计算结果中，请勿关闭或刷新页面"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="tip" style="text-align: left">
      运费计算
    </div>
    <div style="margin-bottom: 10px">
      <el-row :gutter="20" v-loading="loading" element-loading-text="请稍后...">
        <el-col :span="4" :offset="4">
          <el-select v-model="modelSelect" placeholder="模板选择" @change="freightChange" clearable filterable
                     @clear="freightClear">
            <el-option
                v-for="item in freights"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" round @click="addRule">新建规则</el-button>
        </el-col>
        <el-col :span="2" v-if="freight.id === ''">
          <el-button type="success" round @click="addTemp">保存模板</el-button>
        </el-col>
        <el-col :span="2" v-if="freight.id !== ''">
          <el-button type="primary" round @click="updateTemp">更新模板</el-button>
        </el-col>
        <el-col :span="2" v-if="freight.id !== ''">
          <el-button type="primary" round @click="freight.id = '';modelSelect = ''">复制模板</el-button>
        </el-col>
        <el-col :span="2" v-if="freight.id !== ''">
          <el-popover
              placement="top"
              width="160"
              v-model="visible">
            <p>确定要删除当前选中的模板吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="visible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="deleteTemp">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" round>删除模板</el-button>
          </el-popover>
        </el-col>
      </el-row>
    </div>
    <!-- 规则列表 -->
    <div v-for="(item, index) in freight.rules" v-bind:key="index" style="margin: 0 auto"
         v-if="freight.rules.length > 0 && show">
      <div style="margin-top: 10px;margin-bottom: 10px;text-align: left;">
        <el-card shadow="always">
          <el-table style="width: 100%" :data="[item]" :key="item">
            <el-table-column label="目的地/重量KG">
              <template slot-scope="scope">
                                <span v-for="(city, indexRule) in item.cities"
                                      v-bind:key="city + indexRule">{{ city + ' ' }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="itemRule.startWeight + '  - ' +  itemRule.endWeight"
                             v-for="(itemRule, indexRule) in item.ruleItems"
                             v-bind:key="index + '' + indexRule"
            >
              <template slot-scope="scope">
                <span v-if="itemRule.type === '0'">{{ itemRule.price + ' 元/票' }}</span>
                <span v-if="itemRule.type === '1'">{{
                    '首重重量：' + itemRule.calculateFirstWeight + ' kg，首重价格：' + itemRule.firstPrice + '元， 每' + itemRule.secondWeight + ' kg收' + itemRule.secondPrice + '元续重价'
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="230px">
              <template>
                <el-button
                    size="mini"
                    type="primary"
                    @click="ruleEdit(index)">编辑
                </el-button>
                <el-button
                    size="mini"
                    type="warning"
                    @click="ruleCopy(index)">复制
                </el-button>
                <el-button
                    size="mini"
                    type="danger"
                    @click="ruleDelete(index)">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-row :gutter="20" v-loading="loading" element-loading-text="请稍后...">
        <el-col :span="2" :offset="8">
          <el-button type="primary" round @click="addRule">新建规则</el-button>
        </el-col>
        <el-col :span="2" v-if="freight.id === ''">
          <el-button type="success" round @click="addTemp">保存模板</el-button>
        </el-col>
        <el-col :span="2" v-if="freight.id !== ''">
          <el-button type="primary" round @click="updateTemp">更新模板</el-button>
        </el-col>
        <el-col :span="2" v-if="freight.id !== ''">
          <el-button type="primary" round @click="freight.id = '';modelSelect = ''">复制模板</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 整体配置 -->
    <div style="margin: 10px auto 0 auto">
      <el-card shadow="always">
        <div style="text-align: left;margin-bottom: 10px">
          <span style="line-height: 40px">整体配置</span>
          <el-button style="float: right;margin-right: 10px" type="primary" round
                     @click="addSurcharge()">新增附加费
          </el-button>
        </div>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="模板名">
            <el-input v-model="freight.name"></el-input>
          </el-form-item>
          <el-form-item
              label="sheet 正数表示第几个sheet，使用当前页面模板；负数表示汇总，使用已保存模板，按sheet名和模板名匹配">
            <el-input-number v-model="freight.sheetNo" :step="1"></el-input-number>
          </el-form-item>
          <!--          <el-form-item label="省份列">-->
          <!--            <el-select v-model="freight.cityRowNo" placeholder="请选择">-->
          <!--              <el-option-->
          <!--                  v-for="item in ABCSelect"-->
          <!--                  :key="item.value"-->
          <!--                  :label="item.label"-->
          <!--                  :value="item.value">-->
          <!--              </el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="重量列">-->
          <!--            <el-select v-model="freight.weightRowNo" placeholder="请选择">-->
          <!--              <el-option-->
          <!--                  v-for="item in ABCSelect"-->
          <!--                  :key="item.value"-->
          <!--                  :label="item.label"-->
          <!--                  :value="item.value">-->
          <!--              </el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="结果列">-->
          <!--            <el-select v-model="freight.resultRowNo" placeholder="请选择">-->
          <!--              <el-option-->
          <!--                  v-for="item in ABCSelect"-->
          <!--                  :key="item.value"-->
          <!--                  :label="item.label"-->
          <!--                  :value="item.value">-->
          <!--              </el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
        </el-form>
        <div style="text-align: left;"
             v-if="freight.cityPlusList !=null && (freight.cityPlusList.length !==0 || freight.weightPlusList.length !==0 )">
          <span style="line-height: 40px">附加费</span>
        </div>
        <div v-for="(item, index) in freight.cityPlusList" v-bind:key="index"
             style="text-align: left;line-height: 12px;margin: 0;padding: 0;height: 20px">
          {{'省级：' + item.name + '----->' + item.price + '元' }}
          <el-button type="text" @click="deleteCityPlus(index)">删除</el-button>
        </div>
        <div v-for="(item, index) in freight.cityDetailPlusList" v-bind:key="index"
             style="text-align: left;line-height: 12px;margin: 0;padding: 0;height: 20px">
          {{'市级：' + item.name + '----->' + item.price + '元' }}
          <el-button type="text" @click="deleteCityDetailPlus(index)">删除</el-button>
        </div>
        <div v-for="(item, index) in freight.weightPlusList" v-bind:key="index"
             style="text-align: left;line-height: 12px;margin: 0;padding: 0;height: 20px">
          {{'重量：' +  item.startWeight + 'kg  ----->' + item.price + '元' }}
          <el-button type="text" @click="deleteWeightPlus(index)">删除</el-button>
        </div>
      </el-card>
    </div>
    <!-- 文件上传 -->
    <div style="width: 400px;margin: 20px auto 0 auto">
      <el-card shadow="always">
        <div style="text-align: center;margin-bottom: 10px">
          <el-button style="margin-left: 10px;" size="small" type="success" @click="submit">计算已选择文件
          </el-button>
        </div>
        <div style="text-align: left;margin-bottom: 10px">
          <el-upload
              class="upload-demo"
              drag
              action="#"
              multiple
              show-file-list
              :auto-upload="false"
              :file-list="fileList"
              :on-change="uploadChange"
              :on-remove="uploadChange"
              accept=".xls,.xlsx">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将需要计算的文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传xls、xlsx文件，且不超过500kb</div>
          </el-upload>
        </div>
      </el-card>
    </div>

    <!-- 编辑 -->
    <el-dialog
        title="编辑"
        :visible.sync="ruleEditVisible"
        width="1200px"
        :lock-scroll="false"
    >
      <div style="text-align: left;margin-bottom: 10px">
        <el-button type="primary" size="small"
                   @click="bindCityOpen(ruleIndex)" style="margin-right: 10px">
          绑定区域
        </el-button>
        已绑区域：
        <el-tag v-for="(item, indexTag) in rule.cities" v-bind:key="indexTag"
                style="margin-right: 10px" closable @close="tagClose(item, ruleIndex, indexTag)">
          {{ item }}
        </el-tag>
      </div>
      <div style="height: 105px;" v-for="(ruleItem, ruleItemIndex) in rule.ruleItems" v-bind:key="ruleItemIndex">
        <el-container style="height: 100px;border: 1px solid #F0F0F0">
          <el-aside width="30%" style="background-color: #F0F0F0;padding-top: 15px">
            <div>
              重量：
              <el-input-number v-model="ruleItem.startWeight" controls-position="right" size="small"
                               :step="0.01" :min="0" :max="999" :controls="false"
                               disabled></el-input-number>
              -
              <el-input-number v-model="ruleItem.endWeight" controls-position="right" size="small"
                               :step="0.01" :min="0" :max="999" :controls="false"
                               :disabled="ruleItemIndex === rule.ruleItems.length - 1"
                               @change="endWeightChange(ruleItemIndex)"></el-input-number>
            </div>
            <div style="text-align: right;padding: 10px 20px 0 0">
              <el-button type="success" icon="el-icon-plus" circle size="mini"
                         @click="addRuleItem(ruleItemIndex)"
                         v-if="rule.ruleItems.length === 1 || (ruleItemIndex !== rule.ruleItems.length -1 && round(rule.ruleItems[ruleItemIndex+1].endWeight - ruleItem.endWeight) > 0.02)"></el-button>
              <el-button type="danger" icon="el-icon-delete" circle size="mini" v-if="ruleItemIndex !== 0"
                         @click="delRuleItem(ruleItemIndex)"></el-button>
            </div>
          </el-aside>
          <el-main>
            <div style="text-align: left;padding: 15px 0 0 40px">
              <el-radio-group v-model="ruleItem.type">
                <el-radio label="0" border size="small">固定价</el-radio>
                <el-radio label="1" border size="small">步进价</el-radio>
              </el-radio-group>
            </div>
            <div style="text-align: left;padding: 10px 0 0 40px" v-if="ruleItem.type === '0'">
              固定价格（元/票）
              <el-input-number v-model="ruleItem.price" controls-position="right" size="small" :min="0.01"
                               :max="999" :controls="false" style="width: 100px"></el-input-number>
            </div>
            <div style="text-align: left;padding: 10px 0 0 40px" v-if="ruleItem.type === '1'">
              首重重量：
              <el-input-number v-model="ruleItem.calculateFirstWeight" controls-position="right"
                               size="mini" :step="0" :min="0.01" :max="999" :controls="false"
                               style="width: 80px"></el-input-number>
              &nbsp;首重价格(元)：
              <el-input-number v-model="ruleItem.firstPrice" controls-position="right" size="small"
                               :step="0.01" :min="0" :max="999" :controls="false"
                               style="width: 80px"></el-input-number>
              ；&nbsp;每
              <el-input-number v-model="ruleItem.secondWeight" controls-position="right" size="small"
                               :step="0.01" :min="0" :max="999" :controls="false"
                               style="width: 80px"></el-input-number>
              kg 收
              <el-input-number v-model="ruleItem.secondPrice" controls-position="right" size="small"
                               :step="0.01" :min="0" :max="999" :controls="false"
                               style="width: 80px"></el-input-number>
              续重价格
            </div>
          </el-main>
        </el-container>
      </div>
      <span slot="footer" class="dialog-footer">
            <el-button @click="ruleEditVisible = false">取 消</el-button>
            <el-button type="primary" @click="saveRuleItem">确 定</el-button>
            </span>
    </el-dialog>

    <!-- 新增附加费 -->
    <el-dialog
        title="新增附加费"
        :visible.sync="addSurchargeVisible"
        width="500px"
        :lock-scroll="false"
    >
      <template>
        <el-radio v-model="plusType" :label="0">省级附加费</el-radio>
        <el-radio v-model="plusType" :label="1">重量附加费</el-radio>
        <el-radio v-model="plusType" :label="2">市级附加费</el-radio>
      </template>
      <div v-if="plusType === 0" style="margin-top: 40px">
        <el-checkbox-group v-model="selectSurchargeCity">
          <el-checkbox v-for="(item, index) in citySurcharge" v-bind:key="index" :label="item"
                       style="width: 60px"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div v-if="plusType === 1" style="margin-top: 40px">
        重量：
        <el-input-number v-model="plusWeight" :step="0.1"></el-input-number>
      </div>
      <div v-if="plusType === 2" style="margin-top: 40px">
        城市：
        <el-cascader
            v-model="cityDetailPlus.name"
            :options="cityTree"
        >
        </el-cascader>
      </div>
      <div style="margin-top: 40px">
        金额：
        <el-input-number v-model="surchargePrice" :step="0.1"></el-input-number>
      </div>
      <span slot="footer" class="dialog-footer">
            <el-button @click="addSurchargeVisible = false">取 消</el-button>
            <el-button type="primary" @click="addSurchargeCity()">确 定</el-button>
            </span>
    </el-dialog>

    <!-- 自定义快捷选项 -->
    <el-dialog
        title="自定义快捷选项"
        :visible.sync="addCityAutoVisible"
        width="800px"
        :lock-scroll="false"
    >
      <el-checkbox-group v-model="addCityAutoSelect">
        <el-checkbox v-for="(item, index) in city" v-bind:key="index" :label="item"
                     style="width: 60px"></el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
            <el-button @click="addCityAutoVisible = false">取 消</el-button>
            <el-button type="primary" @click="addCityAuto()">确 定</el-button>
            </span>
    </el-dialog>

    <!--自定义删除-->
    <el-dialog
        title="自定义删除"
        :visible.sync="deleteCityAutoVisible"
        width="800px"
        :lock-scroll="false"
    >
      <el-tag v-for="(item, indexTag) in customCityList" v-bind:key="indexTag"
              style="margin-right: 10px" closable @close="customCityClose(item.id)">
        {{ item.cities }}
      </el-tag>
      <span slot="footer" class="dialog-footer">
            <el-button @click="deleteCityAutoVisible = false">取 消</el-button>
            <el-button type="primary" @click="deleteCityAutoVisible = false">确 定</el-button>
            </span>
    </el-dialog>

    <!-- 城市绑定 -->
    <el-dialog
        title="城市绑定"
        :visible.sync="addCityVisible"
        width="800px"
        :lock-scroll="false"
    >
      <div style="margin: 0 22px 30px 24px;text-align: left">
        <div style="margin: 0 0 10px 0">
          快捷多选
        </div>
        <el-checkbox-group v-model="quickSelectCity" @change="quickSelect">
          <el-checkbox label="苏浙沪皖"></el-checkbox>
          <el-checkbox label="粤冀鄂闵豫鲁津赣湘"></el-checkbox>
          <el-checkbox label="京桂晋辽吉川陕云渝黑琼贵"></el-checkbox>
          <el-checkbox label="蒙甘宁青"></el-checkbox>
          <el-checkbox label="新藏"></el-checkbox>
          <el-checkbox label="港澳台"></el-checkbox>
          <div style="margin: 10px 0 0 0" v-if="customCityList!== null && customCityList.length > 0">
            <el-checkbox :label="item.cities" v-for="(item, index) in customCityList"></el-checkbox>
          </div>
        </el-checkbox-group>
        <div style="margin: 0 0 10px 0">
        </div>
        <el-button size="mini" @click="customCity()">添加自定义</el-button>
        <el-button size="mini" type="danger" @click="customDeleteCity()">删除自定义</el-button>
      </div>
      <el-checkbox-group v-model="selectCity">
        <el-checkbox v-for="(item, index) in city" v-bind:key="index" :label="item"
                     style="width: 60px" :disabled="disableCity.indexOf(item) > -1"></el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
            <el-button @click="addCityVisible = false">取 消</el-button>
            <el-button type="primary" @click="bindCity()">确 定</el-button>
            </span>
    </el-dialog>
    <!-- 模板名 -->
    <el-dialog
        title="模板名"
        :visible.sync="addTempVisible"
        width="30%"
        :lock-scroll="false"
    >
      <el-form ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="模板名">
          <el-input v-model="freight.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
            <el-button @click="addTempVisible = false">取 消</el-button>
            <el-button type="primary" @click="freightSave()">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>

import {Message} from "element-ui";
import {
  addAreaGroup, deleteAreaGroup,
  freight,
  freightDel,
  freightDetail,
  freightList,
  freightSave,
  freightUpdate, getAreaGroup, getCity
} from "@/api/frontStage/tools";
import axios from "axios";

export default {
  name: "Freight",
  data() {
    return {
      city: ["北京", "上海", "广东", "浙江", "天津", "重庆", "河北", "山西", "陕西", "四川",
        "贵州", "云南", "甘肃", "湖北", "湖南", "海南", "安徽", "江苏", "吉林", "辽宁",
        "福建", "江西", "山东", "河南", "青海", "西藏", "新疆", "台湾", "广西", "宁夏",
        "香港", "澳门", "内蒙古", "黑龙江"],
      citySurcharge: ["北京", "上海", "广东", "浙江", "天津", "重庆", "河北", "山西", "陕西", "四川",
        "贵州", "云南", "甘肃", "湖北", "湖南", "海南", "安徽", "江苏", "吉林", "辽宁",
        "福建", "江西", "山东", "河南", "青海", "西藏", "新疆", "台湾", "广西", "宁夏",
        "香港", "澳门", "内蒙古", "黑龙江"],
      ABCSelect: [
        {"label": "A", "value": 1},
        {"label": "B", "value": 2},
        {"label": "C", "value": 3},
        {"label": "D", "value": 4},
        {"label": "E", "value": 5},
        {"label": "F", "value": 6},
        {"label": "G", "value": 7},
        {"label": "H", "value": 8},
        {"label": "I", "value": 9},
        {"label": "J", "value": 10},
        {"label": "K", "value": 11},
        {"label": "L", "value": 12},
        {"label": "M", "value": 13},
        {"label": "N", "value": 14},
        {"label": "O", "value": 15},
        {"label": "P", "value": 16},
        {"label": "Q", "value": 17},
        {"label": "R", "value": 18},
        {"label": "S", "value": 19},
        {"label": "T", "value": 20},
        {"label": "U", "value": 21},
        {"label": "V", "value": 22},
        {"label": "W", "value": 23},
        {"label": "X", "value": 24},
        {"label": "Y", "value": 25},
        {"label": "Z", "value": 26}
      ],
      cities: [
        {"label": "苏", "value": "江苏"},
        {"label": "浙", "value": "浙江"},
        {"label": "沪", "value": "上海"},
        {"label": "皖", "value": "安徽"},
        {"label": "粤", "value": "广东"},
        {"label": "冀", "value": "河北"},
        {"label": "鄂", "value": "湖北"},
        {"label": "闵", "value": "福建"},
        {"label": "豫", "value": "河南"},
        {"label": "鲁", "value": "山东"},
        {"label": "津", "value": "天津"},
        {"label": "赣", "value": "江西"},
        {"label": "湘", "value": "湖南"},
        {"label": "京", "value": "北京"},
        {"label": "桂", "value": "广西"},
        {"label": "晋", "value": "山西"},
        {"label": "辽", "value": "辽宁"},
        {"label": "吉", "value": "吉林"},
        {"label": "川", "value": "四川"},
        {"label": "陕", "value": "陕西"},
        {"label": "云", "value": "云南"},
        {"label": "渝", "value": "重庆"},
        {"label": "黑", "value": "黑龙江"},
        {"label": "琼", "value": "海南"},
        {"label": "贵", "value": "贵州"},
        {"label": "蒙", "value": "内蒙古"},
        {"label": "甘", "value": "甘肃"},
        {"label": "宁", "value": "宁夏"},
        {"label": "青", "value": "青海"},
        {"label": "新", "value": "新疆"},
        {"label": "藏", "value": "西藏"},
        {"label": "港", "value": "香港"},
        {"label": "澳", "value": "澳门"},
        {"label": "台", "value": "台湾"}
      ],
      selectCity: [],
      quickSelectCity: [],
      selectSurchargeCity: [],
      disableCity: [],
      models: {},
      modelSelect: '',
      addSurchargeVisible: false,
      addTempVisible: false,
      addCityVisible: false,
      addWeightPriceVisible: false,
      addSecondWeightVisible: false,
      toggleSearchStatus: false,
      addCityAutoVisible: false,
      deleteCityAutoVisible: false,
      visible: false,
      ruleEditVisible: false,
      copyFlag: false,
      weightPriceMin: 0,
      secondWeightMin: 0,
      ruleIndex: 0,
      surchargePrice: 0,
      plusWeight: 0,
      freights: [],
      plusType: 0,
      loading: false,
      freightLoading: false,
      show: true,
      cityTree: [],
      freight: {
        rules: [],
        sheetNo: 2,
        name: '',
        id: '',
        cityRowNo: 6,
        weightRowNo: 11,
        resultRowNo: 12,
        cityPlusList: [],
        weightPlusList: [],
        cityDetailPlusList: []
      },
      rule: {
        name: '',
        ruleItems: [],
      },
      ruleItem: {
        startWeight: '',
        endWeight: {},
        price: {},
        firstPrice: {},
        calculateFirstWeight: {},
        secondWeight: {},
        secondPrice: {},
        type: {}
      },
      // 省附加费
      cityPlus: {
        city: '',
        price: 1,
      },
      // 市附加费
      cityDetailPlus: {
        name: '',
        price: 1,
      },
      // 重量附加费
      weightPlus: {
        weight: '',
        price: 1,
      },
      fileList: [],
      selectCitiesOld: [],
      addCityAutoSelect: [],
      customCityList: [],
    }
  },
  created() {
    this.addRule();
    this.freightList();
  },
  methods: {
    customCityClose(id) {
      deleteAreaGroup({id: id}).then(res => {
        getAreaGroup().then(res => {
          this.customCityList = res.data;
        })
      })
    },
    customDeleteCity() {
      this.deleteCityAutoVisible = true;
    },
    customCity() {
      this.addCityAutoSelect = []
      this.addCityAutoVisible = true;
    },
    addCityAuto() {
      var str = '';
      for (var i = 0; i < this.addCityAutoSelect.length; i++) {
        if (i < this.addCityAutoSelect.length - 1) {
          str += this.addCityAutoSelect[i] + ','
        } else {
          str += this.addCityAutoSelect[i]
        }
      }
      addAreaGroup({cityNames: str}).then(res => {
        if (res.code === 0) {
          getAreaGroup().then(res => {
            this.customCityList = res.data;
          })
          this.addCityAutoVisible = false;
        }
      })
    },
    quickSelect(values) {
      if (this.selectCitiesOld.length < values.length) {
        // 新增
        for (var i = 0; i < this.cities.length; i++) {
          for (var j = 0; j < this.quickSelectCity.length; j++) {
            // 选出对应的城市名
            if (this.quickSelectCity[j].indexOf(this.cities[i].label) !== -1) {
              // 不在已选中且不在以确定的才能选
              if (this.disableCity.indexOf(this.cities[i].value) === -1 && this.selectCity.indexOf(this.cities[i].value) === -1) {
                this.selectCity.push(this.cities[i].value)
              }
            }
          }
        }
        this.selectCitiesOld = JSON.parse(JSON.stringify(values));
      } else {
        // 找到删除的哪一个
        var deleteSimple = ''
        for (var i = 0; i < this.selectCitiesOld.length; i++) {
          if (values.indexOf(this.selectCitiesOld[i]) === -1) {
            deleteSimple = this.selectCitiesOld[i];
          }
        }
        var newSelectCities = []
        for (var i = 0; i < this.selectCity.length; i++) {
          // 获取到城市的简称
          if (deleteSimple.indexOf(this.getSimpleByCityName(this.selectCity[i])) === -1) {
            newSelectCities.push(this.selectCity[i]);
          }
        }
        this.selectCity = JSON.parse(JSON.stringify(newSelectCities));
        this.selectCitiesOld = JSON.parse(JSON.stringify(values));
      }
      // console.log(this.rule.cities);

      // var selectNow = [];
      // for (var i = 0; i <= this.selectCity.length; i++) {
      //   if (this.disableCity.indexOf(this.selectCity[i]) !== -1) {
      //     selectNow.push(this.selectCity[i]);
      //   }
      // }
      // this.selectCity = selectNow;
      // for (var i = 0; i < this.cities.length; i++) {
      //   for (var j = 0; j < values.length; j++) {
      //     if (values[j].indexOf(this.cities[i].label) !== -1 && this.cities[i].label.indexOf(this.disableCity[i].label) === -1) {
      //       this.selectCity.push(this.cities[i].value)
      //       //console.log(this.cities[i].value)
      //     }
      //   }
      // }
    },
    getSimpleByCityName(value) {
      var simple = '';
      for (var i = 0; i < this.cities.length; i++) {
        if (this.cities[i].value === value) {
          simple = this.cities[i].label;
        }
      }
      return simple;
    },
    saveRuleItem() {
      if (this.copyFlag) {
        const ruleItemsNew = []
        for (var i = 0; i < this.freight.rules.length; i++) {
          ruleItemsNew.push(this.freight.rules[i]);
          if (i === this.ruleIndex) {
            ruleItemsNew.push(this.rule);
          }
        }
        this.freight.rules = ruleItemsNew;
      } else {
        this.freight.rules[this.ruleIndex] = this.rule;
      }
      this.ruleEditVisible = false;
      if (this.freight.id !== '') {
        this.updateTemp("auto");
      }
      this.$forceUpdate();
    },
    addRuleItem(ruleItemIndex) {
      const ruleItem = this.rule.ruleItems[ruleItemIndex];
      const ruleItemNext = this.rule.ruleItems[ruleItemIndex + 1];
      var ruleItemNew = {
        startWeight: 0,
        endWeight: 0,
        price: 2,
        firstPrice: 1,
        calculateFirstWeight: 1,
        secondWeight: 1,
        secondPrice: 1,
        type: '0'
      }
      // 情况1 只有一个的时候点加号
      if (this.rule.ruleItems.length === 1) {
        ruleItem.endWeight = 0.5;
        ruleItemNew.startWeight = 0.51;
        ruleItemNew.endWeight = 999;
      }
      // 情况2 当前的end 和 下一个的end 相差0.52及以上
      if (this.rule.ruleItems.length >= 2 && ruleItemNext.endWeight - ruleItem.endWeight >= 0.52) {
        ruleItemNew.startWeight = this.round(ruleItem.endWeight + 0.01);
        ruleItemNew.endWeight = this.round(ruleItem.endWeight + 0.5);
        ruleItemNext.startWeight = this.round(ruleItemNew.endWeight + 0.01);
      }
      // 情况3 当前的end 和 下一个的end 相差0.52以下 0.02以上
      if (this.rule.ruleItems.length >= 2 && ruleItemNext.endWeight - ruleItem.endWeight < 0.52 && ruleItemNext.endWeight - ruleItem.endWeight > 0.02) {
        ruleItemNew.startWeight = this.round(ruleItem.endWeight + 0.01);
        ruleItemNew.endWeight = this.round(ruleItemNext.endWeight - 0.02);
        ruleItemNext.startWeight = this.round(ruleItemNext.endWeight - 0.01);
      }
      // 情况4 当前的end 和 下一个的end 相差0.02及以下
      if (this.rule.ruleItems.length >= 2 && ruleItemNext.endWeight - ruleItem.endWeight <= 0.02) {
        Message.error("两个规则之间已经插不下另一个了 /(ㄒoㄒ)/~~")
        return
      }
      const ruleItemsNew = []
      for (var i = 0; i < this.rule.ruleItems.length; i++) {
        ruleItemsNew.push(this.rule.ruleItems[i]);
        if (i === ruleItemIndex) {
          ruleItemsNew.push(ruleItemNew);
        }
      }
      this.rule.ruleItems = ruleItemsNew;
    },
    delRuleItem(ruleItemIndex) {
      const ruleItemPre = this.rule.ruleItems[ruleItemIndex - 1];
      const ruleItemNext = this.rule.ruleItems[ruleItemIndex + 1];
      // 当列表个数大于等于2的时候 不予许删除第一个
      if (this.rule.ruleItems.length >= 2 && ruleItemIndex === 0) {
        Message.error("当列表个数大于等于2的时候 不予许删除第一个 /(ㄒoㄒ)/~~")
        return
      }
      // 删除最后一个
      if (ruleItemIndex === this.rule.ruleItems.length - 1) {
        ruleItemPre.endWeight = 999;
      }
      // 删除中间的
      if (this.rule.ruleItems.length >= 2 && ruleItemIndex < this.rule.ruleItems.length - 1) {
        ruleItemNext.startWeight = this.round(ruleItemPre.endWeight + 0.01);
      }

      const ruleItemsNew = []
      for (var i = 0; i < this.rule.ruleItems.length; i++) {
        if (ruleItemIndex !== i) {
          ruleItemsNew.push(this.rule.ruleItems[i]);
        }
      }
      this.rule.ruleItems = ruleItemsNew;
    },
    round(num) {
      const factor = Math.pow(10, 2);
      return Math.round(num * factor) / factor;
    },
    endWeightChange(ruleItemIndex) {
      const ruleItem = this.rule.ruleItems[ruleItemIndex];
      const ruleItemNext = this.rule.ruleItems[ruleItemIndex + 1];
      // 情况1 小于等于当前的start                         当前的end就改成当前的start + 0.01  下一条规则的start改为当前的end +0.01
      if (ruleItem.endWeight <= ruleItem.startWeight) {
        this.$nextTick(() => {
          ruleItem.endWeight = this.round(ruleItem.startWeight + 0.01);
          ruleItemNext.startWeight = this.round(ruleItem.endWeight + 0.01);
        })
      }
      // 情况2 大于当前的start、小于下一条规则的start       下一条规则的start = 当前的end +0.01
      if (ruleItem.endWeight > ruleItem.startWeight && ruleItem.endWeight < ruleItemNext.startWeight) {
        this.$nextTick(() => {
          ruleItemNext.startWeight = this.round(ruleItem.endWeight + 0.01);
        })
      }
      // 情况3 大于当前的start、大于吓一条规则的start、小于下一条规则的end     下一条规则的start = 当前的end +0.01
      if (ruleItem.endWeight > ruleItem.startWeight && ruleItem.endWeight > ruleItemNext.startWeight && ruleItem.endWeight < ruleItemNext.endWeight) {
        this.$nextTick(() => {
          ruleItemNext.startWeight = this.round(ruleItem.endWeight + 0.01);
        })
      }
      // 情况4 大于下一条的end    下一条的start=下一条的end-0.01 当前的end=吓一条的start-0.01
      if (ruleItem.endWeight >= ruleItemNext.endWeight) {
        this.$nextTick(() => {
          ruleItemNext.startWeight = this.round(ruleItemNext.endWeight - 0.01);
          ruleItem.endWeight = this.round(ruleItemNext.startWeight - 0.01);
        })
      }
      console.log(this.rule.ruleItems)
    },
    ruleEdit(index) {
      console.log(this.freight.rules[index])
      this.copyFlag = false;
      this.ruleEditVisible = true;
      this.ruleIndex = index;
      this.rule = JSON.parse(JSON.stringify(this.freight.rules[index]));
      if (this.rule.ruleItems.length === 0) {
        var ruleItem = {
          startWeight: 0,
          endWeight: 999,
          price: 2,
          firstPrice: 1,
          calculateFirstWeight: 1,
          secondWeight: 1,
          secondPrice: 1,
          type: '0'
        }
        this.rule.ruleItems.push(ruleItem);
      }
    },
    ruleCopy(index) {
      this.copyFlag = true;
      this.ruleEditVisible = true;
      this.ruleIndex = index;
      this.rule = JSON.parse(JSON.stringify(this.freight.rules[index]));
      this.rule.cities = [];
    },
    ruleDelete(index) {
      var newRules = [];
      for (var i = 0; i < this.freight.rules.length; i++) {
        if (index !== i) {
          newRules.push(this.freight.rules[i])
        }
      }
      this.freight.rules = newRules;
    },
    uploadChange(file, fileList) { // 这一步一定要写
      this.fileList = fileList;
    },
    submit: function () {
      if (this.fileList.length === 0) {
        Message.error("请选择文件")
        return;
      }
      this.freightLoading = true;
      const formData = new FormData();
      this.fileList.forEach(file => {
        console.log(file)
        formData.append('files', file.raw);
      });
      formData.append('freight', new Blob([JSON.stringify(this.freight)], {type: 'application/json'}))
      formData.append('files', this.fileList)
      axios.post("/chick/tools/freight", formData, {
        responseType: 'blob',
        headers: {
          "Authorization": window.localStorage.getItem('token')
        }
      }).then(res => {
        let blob = new Blob([res.data]);
        let url = window.URL.createObjectURL(blob); // 创建 url 并指向 blob
        let a = document.createElement('a');
        a.href = url;
        a.download = '结果.zip';
        a.click();
        window.URL.revokeObjectURL(url); // 释放该 url
        this.freightLoading = false;
      })
      console.log(this.fileList)
    },
    updateTemp(auto) {
      if (auto !== 'auto') {
        this.loading = true;
      }
      freightUpdate(this.freight).then(res => {
        if (this.modelSelect != this.freight.name) {
          this.freightList();
          this.modelSelect = this.freight.name;
        }
        this.loading = false;
      })
    },
    deleteTemp() {
      this.visible = false;
      freightDel({id: this.freight.id}).then(res => {
        if (res.code == 0) {
          this.freightList();
          this.freightClear();
          this.modelSelect = null;
        }
      })
    },
    freightClear() {
      this.freight = {
        rules: [],
        sheetNo: 2,
        name: '',
        id: '',
        cityRowNo: 6,
        weightRowNo: 11,
        resultRowNo: 12,
        cityPlusList: [],
        weightPlusList: []
      }
      this.addRule();

    },
    freightChange(id) {
      if (id !== '') {
        freightDetail({id: id}).then(res => {
          var freight = JSON.parse(JSON.stringify(res.data))
          this.freight = freight
        })
      }
    },
    freightList() {
      freightList().then(res => {
        console.log(res.data)
        this.freights = res.data;
      })
    },
    addTemp() {
      this.addTempVisible = true;
    },
    freightSave() {
      if (this.freight.name === '') {
        Message.error({message: "未填写模板名"});
        return
      }
      freightSave(this.freight).then(res => {
        if (res.code === 0) {
          this.addTempVisible = false;
          this.freightList()
          this.freightClear();
          this.modelSelect = null;
        }
      })
    },
    deleteWeightPlus(index) {
      var weightPlus = [];
      for (var i = 0; i < this.freight.weightPlusList.length; i++) {
        if (index !== i) {
          weightPlus.push(this.freight.weightPlusList[i])
        }
      }
      this.freight.weightPlusList = weightPlus;
    },
    deleteCityPlus(index) {
      var cityPlus = [];
      for (var i = 0; i < this.freight.cityPlusList.length; i++) {
        if (index !== i) {
          cityPlus.push(this.freight.cityPlusList[i])
        }
      }
      this.freight.cityPlusList = cityPlus;
    },
    deleteCityDetailPlus(index) {
      var cityDetailPlus = [];
      for (var i = 0; i < this.freight.cityDetailPlusList.length; i++) {
        if (index !== i) {
          cityDetailPlus.push(this.freight.cityDetailPlusList[i])
        }
      }
      this.freight.cityDetailPlusList = cityDetailPlus;
    },
    addSurcharge() {
      this.selectSurchargeCity = [];
      this.surchargePrice = 0;
      this.addSurchargeVisible = true;
      getCity().then(res => {
        this.cityTree = res.data;
        console.log(res)
      })
    },
    addSurchargeCity() {
      if (this.plusType === 0) {
        for (var i = 0; i < this.selectSurchargeCity.length; i++) {
          const cityPrice = {
            name: this.selectSurchargeCity[i],
            price: this.surchargePrice
          };
          this.freight.cityPlusList.push(cityPrice);
        }
      }
      if (this.plusType === 1) {
        const weightPrice = {
          startWeight: this.plusWeight,
          price: this.surchargePrice
        };
        this.freight.weightPlusList.push(weightPrice);
      }
      this.addSurchargeVisible = false;
      if (this.plusType === 2) {
        const cityDetailPrice = {
          name: this.cityDetailPlus.name[1],
          price: this.surchargePrice
        };
        this.freight.cityDetailPlusList.push(cityDetailPrice);
      }
      this.addSurchargeVisible = false;
    },
    addRule() {
      var rule = {
        name: "规则",
        ruleItems: [],
        cities: [],
      };
      this.freight.rules.push(rule);
    },
    tagClose(city, index, indexTag) {
      this.rule.cities.splice(indexTag, 1)
    },
    bindCity() {
      this.rule.cities = this.selectCity;
      this.addCityVisible = false;
    },
    bindCityOpen(index) {
      this.ruleIndex = index;
      this.quickSelectCity = [];
      this.selectCity = JSON.parse(JSON.stringify(this.rule.cities));
      this.disableCity = [];
      for (var i = 0; i < this.freight.rules.length; i++) {
        if (this.freight.rules[i].cities !== null && this.freight.rules[i].cities !== '') {
          if (index !== i || this.copyFlag) {
            for (var j = 0; j < this.freight.rules[i].cities.length; j++) {
              this.disableCity.push(this.freight.rules[i].cities[j]);
            }
          } else {
            for (var k = 0; k < this.rule.cities.length; k++) {
              this.disableCity.push(this.rule.cities[k]);
            }
          }
        }
      }
      this.addCityVisible = true;
      getAreaGroup().then(res => {
        this.customCityList = res.data;
      })
    }
  }
}
</script>

<style scoped>
.tip {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 0 0 20px 0;
  font-size: 25px;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.el-main {
  margin: 0;
  padding: 0;
}

/deep/ .el-dialog__body {
  padding: 10px 20px 20px 20px;
}
</style>
