<template>
  <div style="padding: 20px">

    <el-table
        :stripe="true"
        border
        :data="tableData"
        style="width: 100%">
      <el-table-column label="配置名" prop="configName" width="200"></el-table-column>
      <el-table-column label="配置值" prop="configValue" width="800"></el-table-column>
      <el-table-column label="配置描述" prop="configDescription" width="200"></el-table-column>
      <el-table-column label="备注" prop="remarks" width="200"></el-table-column>
      <el-table-column
          align="right">
        <template slot="header">
          <el-col :span="18">
            <el-input
                v-model="search"
                size="mini"
                placeholder="输入关键字搜索"
                style="display: inline-block"/>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" size="mini" @click="add">新增</el-button>
          </el-col>
        </template>
        <template slot-scope="scope">
          <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">编辑
          </el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-dialog title="新增配置" :visible.sync="dialogFormVisible" width="50%">
        <div style="height: 100%" class="el-dialog-div">
          <el-form :model="config" :rules="rules" ref="config">
            <el-col :offset="5" :span="12">
              <el-form-item label="配置名" :label-width="formLabelWidth" prop="configName">
                <el-input v-model="config.configName" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="配置值" :label-width="formLabelWidth" prop="configValue">
                <el-input v-model="config.configValue" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="配置描述" :label-width="formLabelWidth" prop="configDescription">
                <el-input v-model="config.configDescription" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="备注" :label-width="formLabelWidth">
                <el-input v-model="config.remarks" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-form>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="save">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {getAllConfigInfo, saveConfig, removeConfig} from "@/api/backStage/config";

export default {
  name: "index",
  data() {
    return {
      tableData: [],
      search: '',
      dialogFormVisible: false,
      formLabelWidth: '120px',
      config: {
        id: '',
        configName: '',
        configValue: '',
        configDescription: '',
        remarks: '',
        delFlag: ''
      },
      rules: {
        configName: [
          {required: true, message: '配置名必填', trigger: 'blur'},
        ],
        configValue: [
          {required: true, message: '配置值必填', trigger: 'blur'},
        ],
        configDescription: [
          {required: true, message: '配置描述必填', trigger: 'blur'},
        ]
      }
    }
  },
  methods: {
    handleEdit(index, row) {
      this.dialogFormVisible = true;
      this.config.id = row.id;
      this.config.configName = row.configName;
      this.config.configValue = row.configValue;
      this.config.configDescription = row.configDescription;
      this.config.remarks = row.remarks;
      this.config.delFlag = row.delFlag;
    },
    handleDelete(index, row) {
      this.$confirm('确定要删除次配置吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          id: row.id,
          delFlag: '1',
        };
        removeConfig(params).then(res => {
          if (res.code == 0) {
            this.dialogFormVisible = false;
            this.$message({message: res.msg, type: 'success'});
            this.getConfigList();
          } else {
            this.$message.error(res.msg)
          }
        }).catch(error => console.error(error));
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    getConfigList() {
      const params = {};
      getAllConfigInfo(params).then(res => {
        if (res.code == 0) {
          this.tableData = res.data;
        } else {
          this.$message.error(res.msg)
        }
      }).catch(error => console.error(error));
    },
    add() {
      this.dialogFormVisible = true;
      this.config.id = '';
      this.config.configName = '';
      this.config.configValue = '';
      this.config.configDescription = '';
      this.config.remarks = '';
      this.config.delFlag = '0';
    },
    save() {
      this.$refs['config'].validate((valid) => {
        if (valid) {
          const params = {
            id: this.config.id,
            configName: this.config.configName,
            configValue: this.config.configValue,
            configDescription: this.config.configDescription,
            remarks: this.config.remarks,
          };
          saveConfig(params).then(res => {
            if (res.code == 0) {
              this.dialogFormVisible = false
              this.$message({message: res.msg, type: 'success'});
              this.getConfigList();
            } else {
              this.$message.error(res.msg)
            }
          }).catch(error => console.error(error));
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
  created() {
    this.getConfigList();
  }
}
</script>

<style scoped>
.el-dialog-div {
  height: 60vh;
  overflow: auto;
}
</style>
