<template>
  <div class="content">
    <div v-for="(item, index) in this.examDetail" :key="index" style="height: auto">
      <div class="tip" style="text-align: left">
        <span>{{ item.typeName }}</span>
      </div>
      <el-row :gutter="20" style="width: 90%;margin: 0 auto;" class="link">
        <el-col :span="6" v-for="(itemChild,indexChild) in item.examDetailList" :key="indexChild"
                style="min-width: 300px;margin-bottom: 20px">
          <a href="javascript:void(0)" @click="jump(itemChild.id, itemChild.open)" style="text-decoration: none">
            <el-card shadow="hover" class="link">
              <span>
                {{ itemChild.detailName }}
              </span>
              <div>
                <span style="font-size: 10px;color: #2d8cf0;" v-if="dickFilterMethod(itemChild.type, $route.query.examType)">
                [{{ itemChild.type | dickFilter($route.query.examType) }}]
                </span>
              </div>
            </el-card>
          </a>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {getExamDetailByExamId} from "@/api/frontStage/exam";
import {LoginBox} from "@/router/user/login";
import {dickFilter} from "@/filters/filters";

export default {
  name: "detail",
  data() {
    return {
      examId: this.$route.query.examId,
      examDetail: []
    }
  },
  dicts: ['EXAM_01','EXAM_02','EXAM_03','EXAM_04','EXAM_05','EXAM_06','EXAM_07','EXAM_08'],
  methods: {
    getExamDetailByExamId() {
      getExamDetailByExamId({examId: this.examId}).then(res => {
        console.log(res)
        this.examDetail = res.data;
      })
    },
    jump(id, open) {
      if (open === '1') {
        this.$message.error("该科目暂未开放，请选择其他试题");
        return;
      }
      if (localStorage.getItem('token') === null || localStorage.getItem('token') ==='' || localStorage.getItem('token') ===undefined){
        this.$message.error("请先登录");
        LoginBox.install();
        return;
      }
      this.$router.push({name: 'examInfo', query: {detailId: id}})
    },
    dickFilterMethod(val, key){
      return dickFilter(val, key) !== undefined
    }
  },
  mounted() {
    this.getExamDetailByExamId();
  }
}
</script>

<style scoped>
.content {
  min-height: 500px;
  height: auto;
  width: 100%;
  background-color: white;
  text-align: center;
}

.link > a:hover {
  color: dodgerblue;
}

.tip {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 0 0 20px 0;
  font-size: 30px;
}
</style>
