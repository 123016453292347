<template>
  <div class="main">
    <el-empty style="width: auto;margin: 0 auto;min-height: 65vh" description="暂无内容"></el-empty>

  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.main {
  height: auto;
  min-height: 900px;
  width: 100%;
}
</style>
