import Request from '@/utils/request';

// 获取考试
export const getExamList = (params) => Request.get('/chick/exam/list', { data: { ...params }, loading: false });
// 保存考试
export const saveExam = (params) => Request.post('/chick/exam/saveExam', { data: { ...params }, loading: false });
// 更新考试
export const updateExam = (params) => Request.post('/chick/exam/update', { data: { ...params }, loading: false });
// 删除考试
export const removeOrRenewExam = (params) => Request.post('/chick/exam/deleteOrRenew', { data: { ...params }, loading: false });

// 获取考试分类
export const getExamTypeList = (params) => Request.get('/chick/examType/list', { data: { ...params }, loading: false });
// 保存考试分类
export const saveExamType = (params) => Request.post('/chick/examType/saveExam', { data: { ...params }, loading: false });
// 更新考试分类
export const updateExamType = (params) => Request.post('/chick/examType/update', { data: { ...params }, loading: false });
// 删除考试分类
export const removeOrRenewExamType = (params) => Request.post('/chick/examType/deleteOrRenew', { data: { ...params }, loading: false });


// 获取考试详情
export const getExamDetailList = (params) => Request.get('/chick/examDetail/getList', { data: { ...params }, loading: false });
// 获取考试（在考试详情中的）
export const getExamListInExamDetail = (params) => Request.get('/chick/examDetail/getExamList', { data: { ...params }, loading: false });
// 根据考试id获取考试中的每个分类
export const getExamDetailByExamId = (params) => Request.get('/chick/examDetail/getExamDetailByExamId', { data: { ...params }, loading: false });
// 根据考试id、详情id、科目id获取知识点
export const getExamQuestionTypeByExamId = (params) => Request.get('/chick/examDetail/getExamQuestionTypeByExamId', { data: { ...params }, loading: false });
// 通过字典获取子项 (管理)
export const getChildrenDataForManager = (params) => Request.get('/chick/examDetail/getChildrenDataForManager', { data: { ...params }, loading: false });
// 保存考试详情
export const saveExamDetail = (params) => Request.post('/chick/examDetail/insert', { data: { ...params }, loading: false });
// 更新考试详情
export const updateExamDetail = (params) => Request.post('/chick/examDetail/update', { data: { ...params }, loading: false });
// 删除考试详情
export const removeOrRenewExamDetail = (params) => Request.post('/chick/examDetail/removeOrRenew', { data: { ...params }, loading: false });

// 获取考试科目
export const getExamSubjectList = (params) => Request.get('/chick/examSubject/getList', { data: { ...params }, loading: false });
// 保存考试科目
export const saveExamSubject = (params) => Request.post('/chick/examSubject/insert', { data: { ...params }, loading: false });
// 更新考试科目
export const updateExamSubject = (params) => Request.post('/chick/examSubject/update', { data: { ...params }, loading: false });
// 删除考试科目
export const removeOrRenewExamSubject = (params) => Request.post('/chick/examSubject/removeOrRenew', { data: { ...params }, loading: false });

// 获取考试题目
export const getExamQuestionList = (params) => Request.get('/chick/examQuestion/getList', { data: { ...params }, loading: false });
// 根据考试id获取考试中的每个分类
export const getExamDetailByExamIdInQuestion = (params) => Request.get('/chick/examQuestion/getExamDetailByExamId', { data: { ...params }, loading: false });
// 根据考试id和详情id获取考试科目
export const getExamSubjectByExamIdInQuestion = (params) => Request.get('/chick/examQuestion/getExamSubjectByExamId', { data: { ...params }, loading: false });
// 保存考试题目
export const saveExamQuestion = (params) => Request.post('/chick/examQuestion/insert', { data: { ...params }, loading: false });
// 更新考试题目
export const updateExamQuestion= (params) => Request.post('/chick/examQuestion/update', { data: { ...params }, loading: false });
// 删除考试题目
export const removeOrRenewExamQuestion = (params) => Request.post('/chick/examQuestion/removeOrRenew', { data: { ...params }, loading: false });

// 获取考试题目类型
export const getExamQuestionTypeList = (params) => Request.get('/chick/examQuestionType/getList', { data: { ...params }, loading: false });
// 保存考试题目类型
export const saveExamQuestionType = (params) => Request.post('/chick/examQuestionType/insert', { data: { ...params }, loading: false });
// 更新考试题目类型
export const updateExamQuestionType = (params) => Request.post('/chick/examQuestionType/update', { data: { ...params }, loading: false });
// 删除考试题目类型
export const removeOrRenewExamQuestionType = (params) => Request.post('/chick/examQuestionType/removeOrRenew', { data: { ...params }, loading: false });


// 查询反馈
export const getErrorPage = (params) => Request.get('/chick/examWeb/getErrorPage', { data: { ...params }, loading: false });
// 查询反馈详情
export const getErrorDetail = (params) => Request.get('/chick/examWeb/getErrorDetail', { data: { ...params }, loading: false });
// 处理反馈
export const handleError = (params) => Request.post('/chick/examWeb/handleError', { data: { ...params }, loading: false });
