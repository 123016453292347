<template>
  <div>
    <div class="tree-list">
      <el-tree :data="data"
               :props="defaultProps"
               :check-strictly="true"
               show-checkbox
               :expand-on-click-node="false"
               ref="tree"
               node-key="menuId"
               @check="handleNodeChange">
      </el-tree>
    </div>
    <div class="tree-detail" v-if="showFlag">
      <el-button type="primary" @click="addObject">增加根节点</el-button>
      <el-col :offset="5" :span="12">
        <el-form ref="backStageMenu" :model="backStageMenu" label-width="80px" :rules="rules">
          <el-form-item label="父项名">
            <el-input :value="getNameById(this.data) ===null?'分类':getNameById(this.data)"
                      disabled></el-input>
          </el-form-item>
          <el-form-item label="资源名" prop='name'>
            <el-input v-model="backStageMenu.name"></el-input>
          </el-form-item>
          <el-form-item label="资源路径" :prop="backStageMenu.path===null? '' : 'path'">
            <el-input v-model="backStageMenu.path" :value="backStageMenu.type==='2'?'分类':backStageMenu.path"
                      :disabled="backStageMenu.type==='2'"></el-input>
          </el-form-item>
          <el-form-item label="保护资源" :hidden="backStageMenu.type==='2'" style="text-align: left;">
            <el-switch v-model="backStageMenu.permission" active-value="protect" inactive-value="anonymous" style="margin: 0 0 0 2%"></el-switch>
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="backStageMenu.type" style="width: 100%">
              <el-option label="分类" value="2"></el-option>
              <el-option label="接口" value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-row>
          <el-button type="primary" @click="updateResource">保存</el-button>
          <el-button type="primary" @click="addMenu" :disabled="backStageMenu.type==='3'">新增子资源</el-button>
          <el-button type="danger" @click="removeResource">删除资源</el-button>
        </el-row>
      </el-col>
    </div>
    <div>
      <el-dialog title="增加资源" :visible.sync="dialogFormVisible" width="50%">
        <div style="height: 100%" class="el-dialog-div">
          <el-form :model="menu" :rules="rules" ref="menu">
            <el-col :offset="5" :span="12">
              <el-form-item label="父项名" >
                <el-input :value="menu.parentMenuName === null?'根元素':menu.parentMenuName" autocomplete="off"
                          disabled></el-input>
              </el-form-item>
              <el-form-item label="资源名称" prop='name'>
                <el-input v-model="menu.name"></el-input>
              </el-form-item>
              <el-form-item label="资源路径" :prop="menu.type==='3'?'path':''">
                <el-input v-model="menu.path" :value="menu.type==='2'?'分类':menu.path"
                          :disabled="menu.type==='2'"></el-input>
              </el-form-item>
              <el-form-item label="保护资源" style="width: 100%;text-align: left;" :hidden="menu.type==='2'">
                <el-switch v-model="menu.permission" :value="menu.type==='2'?'protect':'anonymous'" active-value="protect" inactive-value="anonymous" style="margin: 0 0 0 2%"></el-switch>
              </el-form-item>
              <el-form-item label="类型">
                <el-select v-model="menu.type" style="width: 100%">
                  <el-option label="分类" value="2"></el-option>
                  <el-option label="接口" value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-form>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveResource">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>


<script>
import {getResource, updateResource, saveResource, removeResource} from '@/api/backStage/system'

export default {
  data: function () {
    return {
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      checkedId: {},
      showFlag: false,
      buttonFlag: false,
      backStageMenu: {
        menuId: '',
        parentMenuId: '',
        name: '',
        type: '',
        path: '',
        permission: ''
      },
      menu: {
        parentMenuId: '',
        parentMenuName: '',
        menuId: '',
        name: '',
        type: '',
        path: '',
        permission: ''
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      rules: {
        menuId: [
          {required: true, message: '请输入本项id', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请输入资源名称', trigger: 'blur'},
        ],
        path: [
          {required: true, message: '请输入资源路径', trigger: 'blur'},
        ]
      }
    };
  },
  components: {},
  methods: {
    getNameById(data) {
      for (let i = 0; i < data.length; i++) {
        if (this.backStageMenu.parentMenuId === data[i].menuId) {
          return data[i].name;
        }
        if (data[i].children != null) {
          this.getNameById(data[i].children);
        }
      }
      return null;
    },
    handleNodeChange(data, checked) {
      this.showFlag = true;
      this.buttonFlag = false;
      this.backStageMenu = data;
      if (checked) {
        this.$refs.tree.setCheckedKeys([data.menuId], true)
      }
    },
    addMenu() {
      this.dialogFormVisible = true;
      this.menu.parentMenuId = this.backStageMenu.menuId;
      this.menu.parentMenuName = this.backStageMenu.name;
      this.menu.type = '3';
    },
    addObject() {
      this.dialogFormVisible = true;
      this.menu.parentMenuId = null;
      this.menu.parentMenuName = null;
      this.menu.type = '2';
    },
    saveResource() {
      this.$refs['menu'].validate((valid) => {
        if (valid) {
          const params = {
            parentMenuId: this.menu.parentMenuId === "" ? null : this.menu.parentMenuId,
            menuId: this.menu.menuId,
            name: this.menu.name,
            path: this.menu.type === '2' ? null : this.menu.path,
            type: this.menu.type,
            permission: this.menu.permission
          };
          saveResource(params).then(res => {
            if (res.code == 0) {
              this.dialogFormVisible = false
              this.getResource();
            }
          }).catch(error => console.error(error));
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    updateResource() {
      this.$refs['backStageMenu'].validate((valid) => {
        if (valid) {
          const params = {
            parentMenuId: this.backStageMenu.parentMenuId,
            menuId: this.backStageMenu.menuId,
            name: this.backStageMenu.name,
            path: this.backStageMenu.type === '2' ? null : this.backStageMenu.path,
            type: this.backStageMenu.type,
            permission: this.backStageMenu.permission
          };
          updateResource(params).then(res => {
            if (res.code == 0) {
              this.dialogFormVisible = false
            }
          }).catch(error => console.error(error));
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    removeResource() {
      this.$confirm('此操作将删除该节点, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          menuId: this.backStageMenu.menuId,
        };
        removeResource(params).then(res => {
          if (res.code == 0) {
            this.dialogFormVisible = false;
            this.showFlag = false;
            this.getResource();
          }
        }).catch(error => console.error(error));
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    getResource() {
      const params = {};
      getResource(params).then(res => {
        console.log(res)
        if (res.code == 0) {
          this.backStageMenu = {};
          this.data = res.data;
          this.$refs.tree.setCheckedKeys([]);
          this.showFlag = false;
        } else {
          this.$message.error(res.msg)
        }
      }).catch(error => console.error(error));
    }
  },
  created() {
    this.getResource();
  }
}
</script>

<style scoped>
.tree-list {
  padding: 30px;
  height: auto;
  width: 40%;
  float: left;
  font-size: 12px;
}

.tree-detail {
  background-color: white;
  margin: 30px;
  padding: 50px 0;
  height: auto;
  width: 50%;
  float: left;
  text-align: center;
}

.el-dialog-div {
  height: 60vh;
  overflow: auto;
}
</style>
