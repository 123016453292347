<template>
<!--  <div class="main">-->
<!--    <el-row :gutter="20" style="width: 70%;margin: 0 auto;min-height: 65vh;" v-if="softwareList.length > 0">-->
<!--      <div style="margin: 30px auto;width: 60%" >-->
<!--        <el-input placeholder="请输入要搜索内容" v-model="softwareName" class="input-with-select">-->
<!--          <el-button slot="append" icon="el-icon-search"></el-button>-->
<!--        </el-input>-->
<!--      </div>-->
<!--      <el-col :span="24" v-for="(item,index) in softwareList" v-bind:key="index"-->
<!--              style="text-align:left;margin-bottom: 20px;min-width: 300px">-->
<!--        <el-card shadow="hover" class="link">-->
<!--          <div style="float: left">-->
<!--            <div style="height: 100px;width: 200px;vertical-align: middle;display:table-cell;padding-bottom: 14px">-->
<!--              <router-link :to="{name : 'examDetail', query: {examId:item.id,examType:item.type}}"-->
<!--                           style="text-decoration: none;color: black;">-->
<!--                <el-image :src="item.imageUrl" style="width: 200px;margin: 0 20px"></el-image>-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div style="float: left;width: 60%">-->
<!--            <div style="height: 100px;vertical-align: middle;display:table-cell;margin: 20px">-->
<!--              名称：{{item.softwareName}}-->
<!--              <br>-->
<!--              简介：{{item.description}}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div style="float: left;width: 10%;padding: 0 0 0 100px">-->
<!--            <div style="height: 100px;vertical-align: middle;display:table-cell;margin: 20px">-->
<!--              去下载-->
<!--            </div>-->
<!--          </div>-->
<!--        </el-card>-->
<!--      </el-col>-->
<!--    </el-row>-->
<!--  </div>-->
  <div class="main">
    <el-empty style="width: auto;margin: 0 auto;min-height: 65vh" description="暂无内容"></el-empty>

  </div>
</template>

<script>
import {getSoftwareList} from "@/api/frontStage/software";

export default {
  name: "index",
  data() {
    return {
      query: {
        current: 1,
        size: 16,
        keyword: '',
        delFlag: 0,
        radio: ''
      },
      loading: true,
      softwareName: '',
      softwareList: [],
      pageTotal: 1
    }
  },
  methods: {
    getData() {
      this.loading = true;
      const data = {
        keyword: this.query.keyword,
        current: this.query.current,
        size: this.query.size,
        type: this.query.radio
      }
      getSoftwareList(data).then(res => {
        console.log(res)
        this.softwareList = res.data.records;
        this.pageTotal = res.data.total;
        sessionStorage.setItem('SoftwareCurrent', this.query.current);
        this.loading = false;
      })
    },
  },
  created() {
    this.getData();
  }
}
</script>

<style scoped>
.main {
  min-height: 900px;
  height: auto;
  width: 100%;
}
</style>
