<template>
  <div class="container">
<!--    <div class="breadcrumb">-->
<!--      <div class="breadcrumb-item breadcrumbContainer">-->
<!--        <el-breadcrumb separator-class="el-icon-arrow-left">-->
<!--          <transition-group name="breadcrumb">-->
<!--            <el-breadcrumb-item v-for="(item, index) in lists" :key="index">-->
<!--              <router-link :to="item.path">{{ item.meta.title }}</router-link>-->
<!--            </el-breadcrumb-item>-->
<!--          </transition-group>-->
<!--        </el-breadcrumb>-->
<!--      </div>-->
<!--    </div>-->
    <!--    <el-divider></el-divider>-->
    <div class="toolsContent">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      lists: [],
      detailMatch: {}
    }
  },
  methods: {
    getBreadcrumb() {
      this.lists = [];
      for (let i = 0; i < this.$route.matched.length; i++) {
        let path = this.$route.matched[i].path;
        console.log(path)
        if (path !== '' && !path.endsWith('/')) {
          this.lists.push(this.$route.matched[i]);
        }
      }
    }
  },
  created() {
    // this.getBreadcrumb()
  },
  watch: {
    // $route() {
    //   this.getBreadcrumb();
    // }
  }
}
</script>

<style scoped>
.container {
  min-width: 800px;
  height: auto;
  width: 80%;
  margin: 0 auto;
}

.breadcrumb {
  height: 40px;
  margin-bottom: 30px;
}

.breadcrumbContainer {
  padding: 25px 0 0 0;
}

.toolsContent {
  text-align: center;
  height: auto;
  min-height: 80vh;
  /*background-image: url("../../../assets/请先选择工具.png");*/
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 40px;
}
</style>
