<template>
    <div style="width: 100%;text-align: center;height: auto;margin: 20px 0 0 0" v-loading="loadingFlag">
        <div style="width: 80%;margin: 0 auto">
            <el-collapse v-model="activeNames" v-for="(item) in this.data" :key="item.id">
                <div>
                    <el-collapse-item :title="item.examSubject.name" :name="item.examSubject.id" class="collapse-item">
                        <el-row style="height: 140px;margin-bottom: 20px">
                            <el-col :span="3" style="margin: 45px 0 0 0">
                                <div class="grid-content bg-purple" s>
                                    <span style="font-size: 20px">总试题数</span><br>
                                    <span style="color: #1989fa;font-size: 18px">{{ item.questionCount }}</span>
                                </div>
                            </el-col>
                            <el-col :span="2" style="margin: 45px 0 0 0">
                                <div class="grid-content bg-purple">
                                    <span style="font-size: 20px">做题数</span><br>
                                    <span style="color: #1989fa;font-size: 18px">{{ item.answered }}</span>
                                </div>
                            </el-col>
                            <el-col :span="3" style="margin: 45px 0 0 0">
                                <div class="grid-content bg-purple">
                                    <span style="font-size: 20px">正确数</span><br>
                                    <span style="color: #1989fa;font-size: 18px">{{ item.rightAnswered }}</span>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div class="grid-content bg-purple">
                                    <span style="font-size: 18px">刷题率</span><br>
                                    <el-progress :width="100" :stroke-width="10" type="circle" :ref="item.id"
                                                 :percentage="item.rateStart"
                                                 :color="color"></el-progress>
                                </div>
                            </el-col>
                            <el-col :span="2">
                                <el-button type="text"
                                           @click="getIntelligentExercise(item.examSubject.examId, item.examSubject.id, item.examSubject.detailId)">
                                    <el-image
                                            :src="'https://chickweb.oss-cn-beijing.aliyuncs.com/exam/274662214584f7df44fc919cce8ea71.png'"
                                            class="iconContent"></el-image>
                                    <br>
                                    智能练习
                                </el-button>
                            </el-col>
                            <el-col :span="2">
                                <el-button type="text"
                                           @click="getReal(item.examSubject.examId, item.examSubject.id, item.examSubject.detailId)">
                                    <el-image :src="'https://chickweb.oss-cn-beijing.aliyuncs.com/exam/12312312.png'"
                                              class="iconContent"></el-image>
                                    <br>
                                    历史真题
                                </el-button>
                            </el-col>
                            <el-col :span="2">
                                <el-button type="text" @click="getSimulationTest(item.examSubject.detailId)">
                                    <el-image :src="'https://chickweb.oss-cn-beijing.aliyuncs.com/exam/111.png'"
                                              class="iconContent"></el-image>
                                    <br>
                                    章节练习
                                </el-button>
                            </el-col>
                            <el-col :span="2">
                                <el-button type="text"
                                           @click="getExaminationRecord(item.examSubject.examId, item.examSubject.id, item.examSubject.detailId)">
                                    <el-image :src="'https://chickweb.oss-cn-beijing.aliyuncs.com/exam/54645re.png'"
                                              class="iconContent"></el-image>
                                    <br>
                                    考试记录
                                </el-button>
                            </el-col>
                            <el-col :span="2">
                                <el-button type="text"
                                           @click="getProblemSet(item.examSubject.examId, item.examSubject.id, item.examSubject.detailId)">
                                    <el-image
                                            :src="'https://chickweb.oss-cn-beijing.aliyuncs.com/exam/222fc919cce8ea71.png'"
                                            class="iconContent"></el-image>
                                    <br>
                                    错题集
                                </el-button>
                            </el-col>
                            <el-col :span="2">
                                <el-button type="text" @click="getFavorites(item.examSubject.examId, item.examSubject.id, item.examSubject.detailId)">
                                    <el-image :src="'https://chickweb.oss-cn-beijing.aliyuncs.com/exam/12312312.png'"
                                              class="iconContent"></el-image>
                                    <br>
                                    收藏夹
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                </div>
            </el-collapse>
        </div>
        <!--智能练习-->
        <el-dialog title="智能练习" :visible.sync="intelligentExercise.dialogVisible" width="30%" :lock-scroll=false>
            <el-form ref="form" :model="intelligentExercise.form" label-width="100px">
                <el-form-item label="答题情况">
                    <el-radio-group v-model="intelligentExercise.form.questionType">
                        <el-radio label="1">不限</el-radio>
                        <el-radio label="2">未做题</el-radio>
                        <el-radio label="3">已做题</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="题目数量">
                    <div class="block" style="width: 90%;margin: 0 auto">
                        <el-slider
                                v-model="intelligentExercise.form.count"
                                :max="150"
                                show-input>
                        </el-slider>
                    </div>
                </el-form-item>
                <!--<el-form-item label="正确率">
                  <div class="block" style="width: 90%;margin: 0 auto">
                    <el-slider
                        v-model="intelligentExercise.form.accuracy"
                        range
                        show-stops
                        show-tooltip
                        :max="100">
                    </el-slider>
                  </div>
                </el-form-item>-->
                <el-form-item>
                    <el-button type="primary" @click="goExam('2', '', )">开始练习</el-button>
                    <el-button @click="intelligentExercise.dialogVisible = false">取消</el-button>
                </el-form-item>
            </el-form>


        </el-dialog>

        <!--历史真题-->
        <el-dialog title="历史真题" :visible.sync="real.dialogVisible" width="700px" :lock-scroll=false>
            <el-table
                    v-loading="real.loadingFlag"
                    :data="real.data"
                    style="width: 100%"
                    :stripe="true">
                <el-table-column
                        label="年份"
                        width="150">
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <span style="margin-left: 10px">{{ scope.row.year }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="做题情况"
                        width="400">
                    <template slot-scope="scope">

                        <el-progress :percentage="numFormat(scope.row.doCount /scope.row.allCount)"></el-progress>
                        <!--                            <span style="margin-left: 10px">{{ scope.row.doCount + '/' + scope.row.allCount }}</span>-->
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="110">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                type="primary"
                                @click="goExam('1',scope.row.id)">开始练习
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    layout="prev, pager, next, jumper"
                    :current-page="real.current"
                    :page-size="real.size"
                    :total="real.pageTotal"
                    @current-change="changeReal"
            ></el-pagination>
        </el-dialog>

        <!--章节练习-->
        <el-dialog title="章节练习" :visible.sync="simulationTest.dialogVisible" width="800px" :lock-scroll=false>
            <el-collapse v-model="simulationTest.activeId" accordion v-loading="simulationTest.loadingFlag"
                         @change="change">
                <el-collapse-item :title="item.name + '（'+ item.doCount +'/'+item.allCount+'）'"
                                  v-for="(item) in simulationTest.data" :key="item.id"
                                  :name="item.id">
                    <el-table
                            :data="item.examChapterVOS"
                            stripe
                            style="width: 100%" :show-header="false">
                        <el-table-column
                                prop="name"
                                label="小节"
                                width="600">
                            <template slot-scope="scope">
                                <div>{{
                                    scope.row.name + '（' + scope.row.doCount + '/' + scope.row.allCount + '）'
                                    }}
                                </div>
                                <el-progress
                                        :percentage="numFormat(scope.row.doCount /scope.row.allCount)"></el-progress>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="address"
                                label="开始练习">
                            <template slot-scope="scope">
                                <el-button @click="goExam('3', scope.row.id)" type="success" size="small">开始练习
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-collapse-item>
            </el-collapse>
        </el-dialog>

        <!--考试记录-->
        <el-dialog title="考试记录" :visible.sync="examinationRecord.dialogVisible" width="1250px" :lock-scroll=false>
            <el-table
                    v-loading="examinationRecord.loadingFlag"
                    :data="examinationRecord.data"
                    style="width: 100%"
                    :stripe="true">
                <el-table-column
                        label="创建时间"
                        width="200">
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <span style="margin-left: 10px">{{ scope.row.createDate | formatDateTime }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="类型"
                        width="100">
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.type === '1' ? '真题' : scope.row.type === '2' ? '智能练习' : '章节练习'
                            }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="名称"
                        width="200">
                    <template slot-scope="scope">
                <span>{{
                    scope.row.type === '1' ? scope.row.realName : scope.row.type === '2' ? '智能练习' : scope.row.chapterName
                    }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="总题数"
                        width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.countAll }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="已做题数"
                        width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.countDo }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="正确数"
                        width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.countRight }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="正确率"
                        width="100">
                    <template slot-scope="scope">
                        <span>{{ getRate(scope.row.countRight, scope.row.countDo) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="最后做题时间"
                        width="200">
                    <template slot-scope="scope">
                        <span>{{ scope.row.updateDate | formatDateTime }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="110px">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                type="primary"
                                @click="continueExam(scope.row.id)">继续练习
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    layout="prev, pager, next, jumper"
                    :current-page="examinationRecord.current"
                    :page-size="examinationRecord.size"
                    :total="examinationRecord.pageTotal"
                    @current-change="changeExaminationRecord"
            ></el-pagination>
        </el-dialog>

        <!--错题集-->
        <el-dialog title="错题集" :visible.sync="problemSet.dialogVisible" width="60%" :lock-scroll=false>
            <span v-loading="problemSet.loadingFlag">
            <el-table
                    :data="problemSet.data"
                    style="width: 100%"
                    :stripe="true">
            <el-table-column
                    label="创建时间"
                    width="200">
              <template slot-scope="scope">
                <i class="el-icon-time"></i>
                <span style="margin-left: 10px">{{ scope.row.createDate | formatDateTime }}</span>
              </template>
            </el-table-column>

            <el-table-column
                    label="类型"
                    width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.type === '1' ? '真题' : scope.row.type === '2' ? '智能练习' : '章节练习' }}</span>
              </template>
            </el-table-column>

            <el-table-column
                    label="名称"
                    width="200">
              <template slot-scope="scope">
                <span>{{
                    scope.row.type === '1' ? scope.row.realName : scope.row.type === '2' ? '智能练习' : scope.row.chapterName
                    }}</span>
              </template>
            </el-table-column>

            <el-table-column
                    label="总题数"
                    width="80">
              <template slot-scope="scope">
                <span>{{ scope.row.countAll }}</span>
              </template>
            </el-table-column>

            <el-table-column
                    label="错题数"
                    width="80">
              <template slot-scope="scope">
                <span>{{ scope.row.countDo - scope.row.countRight }}</span>
              </template>
            </el-table-column>
            <el-table-column
                    label="最后做题时间"
                    width="200">
              <template slot-scope="scope">
                <span>{{ scope.row.updateDate | formatDateTime }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="220px">
              <template slot-scope="scope">
                <el-button
                        size="mini"
                        type="primary"
                        @click="continueWrongExam(scope.row.id)">回顾错题
                </el-button>
                  &nbsp;
                <el-popover
                        placement="top"
                        width="160"
                        :ref="`popover-${scope.$index}`"
                        :model="true">
                   <p>确认要清除么？</p>
                   <div style="text-align: right; margin: 0">
                     <el-button size="mini" type="text" @click="scope._self.$refs[`popover-${scope.$index}`].doClose()">取消</el-button>
                     <el-button type="primary" size="mini"
                                @click="clearWrongByRecordId(scope.row.id);scope._self.$refs[`popover-${scope.$index}`].doClose()">确定</el-button>
                   </div>
                   <el-button size="mini" type="danger" slot="reference">清除错题集</el-button>
                </el-popover>
              </template>
            </el-table-column>
        </el-table>
        <el-pagination
                layout="prev, pager, next, jumper"
                :current-page="problemSet.current"
                :page-size="problemSet.size"
                :total="problemSet.pageTotal"
                @current-change="changeExaminationWrongRecord"
        ></el-pagination>
            </span>
        </el-dialog>

        <!--收藏夹-->
        <el-dialog title="收藏夹" :visible.sync="favorites.dialogVisible" width="700" :lock-scroll=false>
            <span v-loading="favorites.loadingFlag">
            <el-table
                    :data="favorites.data"
                    style="width: 100%"
                    :stripe="true">
            <el-table-column
                    label="题目"
                    width="700"
                    show-overflow-tooltip>
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ setText(scope.row.content) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="110px">
              <template slot-scope="scope">
                <el-button
                        size="mini"
                        type="primary"
                        @click="getFavExam(scope.row.questionId)">查看</el-button>
              </template>
            </el-table-column>
        </el-table>
        <el-pagination
                layout="prev, pager, next, jumper"
                :current-page="favorites.current"
                :page-size="favorites.size"
                :total="favorites.pageTotal"
                @current-change="changeFavorites"
        ></el-pagination>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import {
    getExamAnswerHappeningByUserAndDetailId,
    // getRealListByExamIdSubjectId,
    getExaminationWrongRecord,
    getExaminationRecord, createRecord, clearWrongByRecordId
} from "@/api/frontStage/exam";
import {BigNumber} from 'bignumber.js';
import {LoginBox} from "@/router/user/login";
import {formatDateTime} from '@/utils/Date'
import {
    getChapterYear,
    getChapter,
    getChapterDo,
    getRealListByExamIdDetailId,
    getCollect
} from "@/api/frontStage/doExam";

export default {
    name: "examInfo",
    data() {
        return {
            activeNames: [],
            questionCoverage: 0,
            questionCoverageOver: 200,
            color: '#1989fa',
            data: [],
            loadingFlag: false,

            intelligentExercise: {
                dialogVisible: false,
                loadingFlag: false,
                examId: '',
                subjectId: '',
                detailId: '',
                form: {
                    count: 75,
                    //accuracy: [0, 100],
                    questionType: '1' //1、不限，2、未做题，3、已做题
                }
            },

            real: {
                dialogVisible: false,
                current: 1,
                size: 10,
                keyword: '',
                delFlag: 0,
                loadingFlag: false,
                data: [],
                pageTotal: 1,
                examId: '',
                subjectId: '',
                detailId: ''
            },

            simulationTest: {
                dialogVisible: false,
                current: 1,
                size: 10,
                keyword: '',
                delFlag: 0,
                loadingFlag: false,
                examId: '',
                subjectId: '',
                detailId: '',
                activeId: '',
                data: [],
                yearIds: []
            },

            examinationRecord: {
                dialogVisible: false,
                current: 1,
                size: 10,
                keyword: '',
                delFlag: 0,
                loadingFlag: false,
                data: [],
                examId: '',
                subjectId: '',
                detailId: ''
            },

            problemSet: {
                dialogVisible: false,
                current: 1,
                size: 10,
                keyword: '',
                delFlag: 0,
                loadingFlag: false,
                examId: '',
                subjectId: '',
                detailId: '',
                visible: false
            },

            favorites: {
                dialogVisible: false,
                current: 1,
                size: 10,
                keyword: '',
                delFlag: 0,
                loadingFlag: false,
                examId: '',
                subjectId: '',
                detailId: ''
            }
        }
    },
    methods: {
        change(val) {
            if (val == '' || val == null) {
                return;
            }
            const data = {
                detailId: this.simulationTest.detailId,
                yearIds: null,
                chapterParentId: val
            }
            this.simulationTest.activeId = val;
            getChapterDo(data).then(res => {
                console.log(res)
                console.log(this.simulationTest.data)
                for(var i =0 ;i <this.simulationTest.data.length;i++){
                    if (val == this.simulationTest.data[i].id){
                        for(var j = 0; j< res.data.length; j++){
                            for (var h = 0; h< this.simulationTest.data[i].examChapterVOS.length; h++){
                                if(this.simulationTest.data[i].examChapterVOS[h].id == res.data[j].id){
                                    console.log(this.simulationTest.data[i].examChapterVOS[h].id)
                                    this.simulationTest.data[i].examChapterVOS[h].doCount = res.data[j].doCount
                                    continue;
                                }
                            }
                        }
                    }
                }

                // for(var i =0 ;i <this.simulationTest.data[e].examChapterVOS.length;i++){
                //     for(var j = 0; j< res.data.length; j++){
                //         if(this.simulationTest.data[e].examChapterVOS[i].id == res.data[j].id){
                //             this.simulationTest.data[e].examChapterVOS[i].doCount = res.data[j].doCount
                //             continue;
                //         }
                //     }
                // }
            })
        },
        // 去除标签
        setText(html) {
            let txt = document.createElement("div");
            txt.innerHTML = html;
            let content = txt.innerText || txt.textContent;
            txt = null;
            return content;
        },
        getExamAnswerHappeningByUserAndDetailId() {
            if (this.checkLogin()) {
                this.goIndex();
            }
            this.loadingFlag = true;
            getExamAnswerHappeningByUserAndDetailId({detailId: this.$route.query.detailId}).then(res => {
                this.loadingFlag = false;
                this.data = res.data;
                for (let i = 0; i < res.data.length; i++) {
                    this.activeNames.push(this.data[i].examSubject.id)
                    setTimeout(() => {   //设置延迟执行
                        if (this.data[i].rateStart < this.data[i].rateEnd) {
                            this.data[i].rateStart = BigNumber(this.data[i].rateStart).plus(BigNumber(this.data[i].rateEnd)).toNumber();
                        }
                        if (this.data[i].rateStart === 100) {
                            this.color = '#5cb87a'
                        }
                    }, 100);
                }
            })
        },
        getIntelligentExercise(examId, subjectId, detailId) {
            this.intelligentExercise.dialogVisible = true;
            this.intelligentExercise.examId = examId;
            this.intelligentExercise.subjectId = subjectId;
            this.intelligentExercise.detailId = detailId;
        },
        getReal(examId, subjectId, detailId) {
            if (this.checkLogin()) {
                this.goIndex();
            }
            this.real.dialogVisible = true;
            this.real.loadingFlag = true;
            this.real.examId = examId;
            this.real.subjectId = subjectId;
            const data = {
                keyword: this.real.keyword,
                current: this.real.current,
                size: this.real.size,
                delFlag: this.real.delFlag,
                examId: examId,
                subjectId: subjectId,
                detailId: detailId
            }
            getRealListByExamIdDetailId(data).then(res => {
                this.real.data = res.data.records;
                this.real.pageTotal = res.data.total;
                this.real.loadingFlag = false;
            })
        },
        // 获取错题集
        getExaminationWrongRecord(examId, subjectId, detailId) {
            if (this.checkLogin()) {
                this.goIndex();
            }
            this.problemSet.loadingFlag = true;
            this.problemSet.examId = examId;
            this.problemSet.subjectId = subjectId;
            this.problemSet.detailId = detailId;
            const data = {
                keyword: this.problemSet.keyword,
                current: this.problemSet.current,
                size: this.problemSet.size,
                delFlag: this.problemSet.delFlag,
                examId: examId,
                subjectId: subjectId,
                detailId: detailId
            }
            getExaminationWrongRecord(data).then(res => {
                this.problemSet.data = res.data.records;
                this.problemSet.pageTotal = res.data.total;
                this.problemSet.loadingFlag = false;
            })
        },
        getSimulationTest(detailId) {
            if (this.checkLogin()) {
                this.goIndex();
            }
            this.simulationTest.dialogVisible = true;
            this.simulationTest.detailId = detailId;
            this.getChapter(detailId);
        },
        // 获取做题记录
        getExaminationRecord(examId, subjectId, detailId) {
            if (this.checkLogin()) {
                this.goIndex();
            }
            this.examinationRecord.dialogVisible = true;
            // 查询做题记录
            this.examinationRecord.dialogVisible = true;
            this.examinationRecord.loadingFlag = true;
            this.examinationRecord.examId = examId;
            this.examinationRecord.subjectId = subjectId;
            this.examinationRecord.detailId = detailId;
            const data = {
                keyword: this.examinationRecord.keyword,
                current: this.examinationRecord.current,
                size: this.examinationRecord.size,
                delFlag: this.examinationRecord.delFlag,
                examId: examId,
                subjectId: subjectId,
                detailId: detailId
            }
            getExaminationRecord(data).then(res => {
                this.examinationRecord.data = res.data.records;
                this.examinationRecord.pageTotal = res.data.total;
                this.examinationRecord.loadingFlag = false;
            })
        },
        // 获取章节
        getChapter(detailId) {
            getChapterYear({detailId: detailId}).then(res => {
                this.simulationTest.data = res.data;
                if (this.simulationTest.activeId != '' || this.simulationTest.activeId !=null){
                    this.change(this.simulationTest.activeId);
                }
            })
            // getChapter({detailId: detailId}).then(res => {
            //     this.simulationTest.data = res.data;
            // })
        },
        getChapterDo(detailId) {
            getChapterYear({detailId: detailId}).then(res => {
                this.simulationTest.data = res.data;
            })
            // getChapter({detailId: detailId}).then(res => {
            //     this.simulationTest.data = res.data;
            // })
        },
        // 获取收藏夹
        getCollect() {
            if (this.checkLogin()) {
                this.goIndex();
            }
            this.favorites.dialogVisible = true;
            // 查询做题记录
            this.favorites.loadingFlag = true;
            const data = {
                keyword: this.favorites.keyword,
                current: this.favorites.current,
                size: this.favorites.size,
                delFlag: this.favorites.delFlag,
                detailId: this.favorites.detailId
            }
            getCollect(data).then(res => {
                console.log(res)
                this.favorites.data = res.data.records;
                this.favorites.pageTotal = res.data.total;
                this.favorites.loadingFlag = false;
            })
        },
        //打开错题集页面
        getProblemSet(examId, subjectId, detailId) {
            this.problemSet.dialogVisible = true;
            this.getExaminationWrongRecord(examId, subjectId, detailId);
        },
        // 打开收藏夹
        getFavorites(examId, subjectId, detailId) {
            this.favorites.dialogVisible = true;
            this.favorites.detailId = detailId;
            this.getCollect(examId, subjectId, detailId);
        },
        // 检查是否登录
        checkLogin() {
            if (localStorage.getItem('token') === null || localStorage.getItem('token') === '' || localStorage.getItem('token') === undefined) {
                this.$message.error("请先登录");
                LoginBox.install();
                return true;
            }
        },
        // 整体翻页
        changeReal(val) {
            this.real.current = val;
            this.getReal(this.real.examId, this.real.subjectId, this.real.detailId);
        },
        // 做题记录翻页
        changeExaminationRecord(val) {
            this.examinationRecord.current = val;
            this.getExaminationRecord(this.examinationRecord.examId, this.examinationRecord.subjectId, this.examinationRecord.detailId);
        },
        // 错题翻页
        changeExaminationWrongRecord(val) {
            this.problemSet.current = val;
            this.getExaminationWrongRecord(this.problemSet.examId, this.problemSet.subjectId, this.problemSet.detailId);
        },
        // 收藏夹翻页
        changeFavorites(val) {
            this.favorites.current = val;
            this.getCollect(this.favorites.examId, this.favorites.subjectId, this.favorites.detailId);
        },
        // 做错题
        continueWrongExam(id) {
            let routeData = this.$router.resolve({
                path: '/goWrongExam',
                query: {
                    recordId: id
                }
            });
            window.open(routeData.href, '_blank');
        },
        clearWrongByRecordId(recordId) {
            clearWrongByRecordId({recordId: recordId}).then(res => {
                this.getExaminationWrongRecord(this.problemSet.examId, this.problemSet.subjectId, this.problemSet.detailId);
            })
            this.problemSet.visible = false;
        },
        // 收藏夹
        getFavExam(id) {
            let routeData = this.$router.resolve({
                path: '/goFavExam',
                query: {
                    questionId: id
                }
            });
            window.open(routeData.href, '_blank');
        },
        goExam(type, id) {
            // type 1：真题 2：智能 3：模拟
            if (this.checkLogin()) {
                this.goIndex();
            }
            var data = {};
            if (type === '1') {
                data = {
                    type: type,
                    realId: id
                }
            }
            if (type === '2') {
                data = {
                    type: type,
                    examId: this.intelligentExercise.examId,
                    subjectId: this.intelligentExercise.subjectId,
                    detailId: this.intelligentExercise.detailId,
                    count: this.intelligentExercise.form.count,
                    questionType: this.intelligentExercise.form.questionType
                }
            }
            if (type === '3') {
                data = {
                    type: type,
                    questionTypeId: id,
                }
            }
            createRecord(data).then(res => {
                if (res.code === 1) {
                    return;
                }
                let routeData = this.$router.resolve({
                    path: '/goExam',
                    query: {
                        recordId: res.data
                    }
                });
                window.open(routeData.href, '_blank');
            })
        },
        continueExam(recordId) {
            // type 1：真题 2：智能 3：模拟
            if (this.checkLogin()) {
                return;
            }
            let routeData = this.$router.resolve({
                path: '/goExam',
                query: {
                    recordId: recordId,
                }
            });
            window.open(routeData.href, '_blank');
        },
        getRate(up, down) {
            if (up === 0 || down === 0) {
                return 0 + "%";
            }
            return parseInt(up * 100 / down) + "%";
        },
        goIndex() {
            this.$router.push({
                path: '/examDetail',
                query: {examId: "0282b9e4-0c58-4dfa-b3f1-ddd29f3fc1a7", examType: 'EXAM_01'}
            });
        },
        numFormat(value) {
            // 截取当前数据到小数点后两位
            let realVal = parseFloat(value).toFixed(2)
            return parseInt(realVal * 100)
        }
    },
    filters: {
        formatDateTime(time) {
            return formatDateTime(time);
        },
        // 去除标签
        setText(html) {
            let txt = document.createElement("div");
            txt.innerHTML = html;
            let content = txt.innerText || txt.textContent;
            txt = null;
            return content;
        }
    },
    mounted() {
        if (this.checkLogin()) {
            return;
        }
        this.getExamAnswerHappeningByUserAndDetailId()
    }
}
</script>

<style scoped>
/deep/ .el-collapse-item__header {
    font-size: 20px;
    color: #1989fa;
}

/deep/ .el-progress-bar__innerText {
    color: black;
}

.iconContent {
    margin: 35px 0 0 0;
    height: 40px;
    width: 40px;
}
</style>
