<template>
  <div class="toolsTitle" v-loading="loading" style="height: auto;min-height: 600px;">
    <div style="height: 20px;width: auto;margin: 0 0 20px 0">
      <el-radio v-model="query.radio" label="" @change="changeType">所有</el-radio>
      <el-radio v-model="query.radio" :label="key" v-for="(value, key) in this.dict.NOVEL" v-bind:key="key"
                @change="changeType">
        {{ value }}
      </el-radio>
    </div>
    <el-empty style="width: auto;margin: 0 auto;min-height: 65vh" v-if="novelList.length === 0"
              description="暂无内容"></el-empty>

    <el-row :gutter="20" style="width: 90%;margin: 0 auto;min-height: 65vh;" v-if="novelList.length > 0">
      <el-col :span="6" v-for="(item,index) in novelList" v-bind:key="index"
              style="text-align:left;margin-bottom: 20px;min-width: 300px">
        <router-link :to="{path:'/chapter',query:{id:item.indexUrl}}">
          <el-card shadow="hover" class="link" >
            <div style="height: auto;width: 40%;float: left;margin-bottom: 15px">
              <el-image :src="item.imageUrl" style="width: 98px;height: 140px;margin: 0 10px 0 0">
                <div slot="error" class="image-slot">
                  <el-image :src="'https://www.qb5.tw/modules/article/images/nocover.jpg'"
                            style="width: 98px;height: 140px;margin: 0 10px 0 0"></el-image>
                </div>
              </el-image>
            </div>
            <div style="height: 100px;width: 60%;float: left">
              <div style="margin-bottom: 10px">
                书名：<span>{{ item.name }}</span>
              </div>
              <div style="margin-bottom: 10px">
                作者：<span>{{ item.author}}</span>
              </div>
              <div style="margin-bottom: 10px">
                来源：<span>{{ item.source}}</span>
              </div>
              <div style="margin-bottom: 10px">
                <el-button @click="addRouter(item.path)" type="primary" style="width: 100%" round>开始阅读</el-button>
              </div>
            </div>
          </el-card>
        </router-link>

      </el-col>
    </el-row>
    <br>
    <div class="pagination">
      <el-pagination
          layout="prev, pager, next, jumper"
          :current-page="query.current"
          :page-size="query.size"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {getNovelThirdPartList} from "@/api/frontStage/novel";

export default {
  name: "NovelIndex",
  data() {
    return {
      query: {
        current: 1,
        size: 40,
        keyword: '',
        delFlag: 0,
        radio: ''
      },
      loading: true,
      novelList: [],
      pageTotal: 1
    }
  },
  dicts: ['NOVEL'],
  methods: {
    getData() {
      //console.log(localStorage.getItem('TOOL'));//获取数组
      //console.log(this.dict)//获取列表
      this.loading = true;
      const data = {
        keyword: this.query.keyword,
        current: this.query.current,
        size: this.query.size,
        type: this.query.radio,
        delFlag: this.query.delFlag
      }
      getNovelThirdPartList(data).then(res => {
        console.log(res);
        this.novelList = res.data.records;
        this.pageTotal = res.data.total;
        this.query.size = res.data.size;
        this.query.current = res.data.current;
        sessionStorage.setItem('novelCurrent', this.query.current);
        this.loading = false;
      })
    },
    changeType() {
      this.getData();
    },
    handlePageChange(val) {
      this.$set(this.query, 'current', val);
      // target.scrollIntoView();
      this.getData();
    },
    addRouter(path) {
      this.$router.push(path);
    }
  },
  mounted() {
    if (sessionStorage.getItem('novelCurrent') != null) {
      this.query.current = parseInt(sessionStorage.getItem('novelCurrent'));
    }
    this.getData();
  },
  computed: {
    list() {
      return this.$route.matched;
    }
  },
  filters: {
    //处理函数
    addPriceIcon(value) {
      console.log(value)//200
      return '¥' + value
    }
  }
}
</script>

<style scoped>
.toolsTitle {
  text-align: center;
}

.link > a:hover {
  color: dodgerblue;
}
</style>
